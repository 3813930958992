import React from 'react';
import "./LitigeReglementView.css"
import {useTranslation} from "react-i18next";

const LitigeReglementView = (props) => {
    const {t, i18n} = useTranslation();
    return <div className={'LitigeReglementView'}>
        <div><span className={'text-muted'}>{t('nomEntiteResponsable')}:</span>{props.data.nomEntiteResponsable}</div>
        <div><span className={'text-muted'}>{t('compatibiliteUdrpIcann')}:</span>{props.data.compatibiliteUdrpIcann?t('yes'):t('no')}</div>
        <div><span className={'text-muted'}>{t('dureeMoyenne')}:</span>{props.data.dureeMoyenne}</div>
        <div><span className={'text-muted'}>{t('existenceProcedure')}:</span>{props.data.existenceProcedure?t('yes'):t('no')}</div>
        <div><span className={'text-muted'}>{t('nombreDossier')}:</span>{props.data.nombreDossier}</div>
    </div>;
};

export default LitigeReglementView;
