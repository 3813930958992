import React from 'react';
import {FlexGrid, FlexGridItem} from "baseui/flex-grid";
import RegistreViewWidget from "../widgets/RegistreViewWidget";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {Spinner} from "baseui/spinner";

const DashStats = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const history = useHistory()
  const params = useParams()
  const [registries,setRegistries] = React.useState(undefined)
  const [registrars,setRegistrars] = React.useState(undefined)

  React.useEffect(()=>{
    if(states.registries) setRegistries(states.registries)
    if(states.registrars) setRegistrars(states.registrars)
  },[states.registries,states.registrars])

  return <div id={'DashStats'}>
    {!registries && <Spinner color={'#fe9957'}/>}
    {registries?.length === 0 && <div>{t('no_data_yet')}</div>}
    <div style={{display:"block",margin:64}}/>
    <div>
      <div hidden={!states.registries?.length}>{t('your_records')}</div>
      <FlexGrid
          flexGridColumnCount={[1, 1, 2, 3]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
      >
        <FlexGridItem>
          <div style={{textAlign:"center"}}>
            <div hidden={!states.registries?.length} style={{fontSize:100}}>{registries?.length}</div>
            <div hidden={!states.registries?.length}>{t('registries')}</div>
          </div>
        </FlexGridItem>
        <FlexGridItem>
          <div style={{textAlign:"center"}}>
            <div hidden={!states.registrars?.length} style={{fontSize:100}}>{registrars?.length}</div>
            <div hidden={!states.registrars?.length}>{t('registrars')}</div>
          </div>
        </FlexGridItem>
      </FlexGrid>
    </div>
    <div style={{display:"block",margin:64}}/>
    <div>
      <div hidden={!states.registriesCount?.length}>{t('all_records')}</div>
      <FlexGrid
          flexGridColumnCount={[1, 1, 2, 3]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
      >
        <FlexGridItem>
          <div style={{textAlign: "center"}}>
            <div hidden={!states.registriesCount?.length} style={{fontSize: 100}}>{states.registriesCount?.length}</div>
            <div hidden={!states.registriesCount?.length}>{t('registries')}</div>
          </div>
        </FlexGridItem>
        <FlexGridItem>
          <div style={{textAlign: "center"}}>
            <div hidden={!states.registrarsCount?.length} style={{fontSize: 100}}>{states.registrarsCount?.length}</div>
            <div hidden={!states.registrarsCount?.length}>{t('registrars')}</div>
          </div>
        </FlexGridItem>
      </FlexGrid>
    </div>

  </div>;
};

export default DashStats;
