import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {getSomeDirections} from "../../../redux/actions";
import {FlexGrid, FlexGridItem} from "baseui/flex-grid";
import DirectionView from "../../views/DirectionView";
import {Button} from "baseui/button";
import {Modal, ModalBody, ModalHeader} from "baseui/modal";
import DirectionForm from "../../forms/DirectionForm";
import {Spinner} from "baseui/spinner";

const DirectionList = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const [isOpen, setIsOpen] = React.useState(false);
    const history = useHistory()
    React.useLayoutEffect(() => {
        dispatch(getSomeDirections(props.registry))
    }, [])
    const [list, setList] = React.useState(undefined);
    React.useLayoutEffect(() => {
        if (states.someDirections) setList(states.someDirections)
    }, [states.someDirections])

    return <div id={'DirectionList'}>
        {!list && <Spinner color={'#fe9957'}/>}
        {list?.length===0 && <div>{t('no_data_yet')}</div>}
        <FlexGrid
            flexGridColumnCount={[1, 1, 2, 2]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
        >
            {list?.map(d => <FlexGridItem>
                <DirectionView data={d}/>
            </FlexGridItem>)}
        </FlexGrid>
        <p>
            {states.utilisateur?.id === states.registry?.Utilisateur && <Button onClick={e=>setIsOpen(true)}>{t('add_Direction')}</Button>}
        </p>

        <Modal
            onClose={() => setIsOpen(false)}
            closeable
            isOpen={isOpen}
            animate
            autoFocus
        >
            <ModalHeader/>
            <ModalBody>
                <DirectionForm registry={props.registry} close={setIsOpen}/>
            </ModalBody>
        </Modal>
    </div>;
};

export default DirectionList;
