import React from 'react';
import {Controller, useForm} from "react-hook-form";
import {Select} from "baseui/select";
import countries from "../../../scripts/countries";
import {FormControl} from "baseui/form-control";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {insertRegistrar, update} from "../../../redux/actions";
import {Button} from "baseui/button";

const UtilisateurView = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const {register, handleSubmit, watch, errors, control} = useForm();
  const onSubmit = data => {
    let o = {
      ...props.data,
      "status": data.status?.option?.id || props.status,
      "Role": data.role?.option?.id || props.Role
    }
    dispatch(update(o,'Utilisateur','users'))
    props.close(false)
  }
  return <div id={'UtilisateurView'}>
    <h3>{props.data.prenom.toUpperCase()} {props.data.nom.toUpperCase()}</h3>
    <hr/>
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl label={() => t('status')}>
          <>
            <Controller
                name="status"
                control={control}
                rules={{required: true}}
                defaultValue={{value:{label:props.data.status,id:props.data.status}}}
                render={({onChange, value}) => <Select
                    options={[{label:t('CREATED'),id:'CREATED'},{label:t('REJECTED'),id:'REJECTED'},{label:t('BANNED'),id:'BANNED'},{label:t('ACTIVE'),id:'ACTIVE'},{label:t('INACTIVE'),id:'INACTIVE'}]}
                    value={value.value}
                    // placeholder="Select"
                    onChange={onChange}
                    defaultValue={{label:t('CREATED'),id:'CREATED'}}
                />}
            />
            {errors.status && <span>{t('field_required')}</span>}
          </>
        </FormControl>
        <FormControl label={() => t('role')}>
          <>
            <Controller
                name="role"
                control={control}
                rules={{required: true}}
                defaultValue={{value:{label:props.data.Role,id:props.data.Role}}}
                render={({onChange, value}) => <Select
                    options={states.roles.map(r=>{
                      r.label = r.libelle
                      return r;
                    })}
                    value={value.value}
                    // placeholder="Select"
                    onChange={onChange}
                />}
            />
            {errors.status && <span>{t('field_required')}</span>}
          </>
        </FormControl>
        <Button type={'submit'}>{t('enregistrer')}</Button>
      </form>
    </div>
  </div>;
};

export default UtilisateurView;
