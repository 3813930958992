import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

const RoleWidget = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const [role,setRole] = React.useState({})
  React.useEffect(()=>{
    let r = states.roles?.filter(r=>r.id===props.id)[0]
    setRole(r)
  },[])
  return <div className={'RoleWidget'}>
    {role.libelle}
  </div>;
};

export default RoleWidget;
