import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getUsers} from "../../../redux/actions";
import DataTable from 'react-data-table-component';
import {Button, SIZE, SHAPE} from "baseui/button";
import SectionTitle from "../../widgets/SectionTitle";
import RoleWidget from "../../widgets/RoleWidget";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "baseui/modal";
import UtilisateurView from "../../views/UtilisateurView";
import {Spinner} from "baseui/spinner";

const UserList = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const [isOpen,setIsOpen] = React.useState()
    const [utilisateur,setUtilisateur] = React.useState()

    React.useEffect(() => {
        dispatch(getUsers())
    }, [])

    const columns = [
        {
            name: t('name'),
            selector: 'nom',
            sortable: true,
            cell: row => row.nom + ' ' + row.prenom
        },
        {
            name: t('email'),
            selector: 'email',
            sortable: true,
        },
        {
            name: t('role'),
            selector: 'Role',
            sortable: true,
            cell:row => <RoleWidget id={row.Role}/>
        },
        {
            name: t('status'),
            selector: 'status',
            sortable: true,
            cell: row => t(row.status)
        },
        {
            name: t('action'),
            selector: 'email',
            sortable: true,
            right: true,
            cell: row => <div>
                {/*<Button*/}
                {/*    onClick={() => alert("click")}*/}
                {/*    size={SIZE.mini}*/}
                {/*>*/}
                {/*    deactiver*/}
                {/*</Button>*/}
                {/*{' '}*/}
                <Button
                    onClick={() => {
                        setUtilisateur(row)
                        setIsOpen(true)
                    }}
                    size={SIZE.mini}
                >
                    {t('edit')}
                </Button>

            </div>
        }
    ];

    return <div id={'UserList'}>
        <SectionTitle title={t('UserList')}/>
        {!states.users?.length && <Spinner color={'#fe9957'}/>}
        <DataTable
            columns={columns}
            data={states.users}
            noHeader
        />
        <Modal
            onClose={() => setIsOpen(false)}
            closeable
            isOpen={isOpen}
            animate
            autoFocus
            role={'dialog'}
        >
            <ModalHeader> </ModalHeader>
            <ModalBody>
                <UtilisateurView data={utilisateur} close={setIsOpen}/>
            </ModalBody>

        </Modal>
    </div>;
};

export default UserList;
