import React from 'react';
import {Client as Styletron} from 'styletron-engine-atomic';
import {Provider as StyletronProvider} from 'styletron-react';
import {LightTheme, DarkTheme, BaseProvider, styled} from 'baseui';
import {StatefulInput} from 'baseui/input';
import {createTheme} from 'baseui';


import './App.css';
import './App.sass';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Welcome from "./Welcome";
import Backdoor from "./Backdoor";
import Stateful from "./Stateful";
import Dash from "./Dash";
import Auth from "./Auth";
import i18n from "i18next";
import Cookies from "universal-cookie";
import axios from "axios";

const engine = new Styletron();
const primitives = {
    primary: '#FE9957',
    accent: '#ff0000',
    // primary300: '#FFA379',
    // primary400: '#D17333',
    // primary500: '#A54E0C',
    // primary700: '#7A2B00',
};
const theme = createTheme(primitives);

function App(props) {
    i18n.changeLanguage('fr')
    const cookie = new Cookies()
    return (
        <StyletronProvider value={engine}>
            <BaseProvider theme={theme}>
                <div className={'AppShell'} id={'AppShell'}>
                    <Router>
                        <Switch>
                            <Route exact path="/">
                                {/*<Welcome/>*/}
                                <Auth/>
                            </Route>
                            <Route path="/backdoor">
                                <Backdoor/>
                            </Route>
                            <Route path="/stateful">
                                <Stateful/>
                            </Route>
                            <Route path="/auth">
                                <Auth/>
                            </Route>
                            <Route path="/dash">
                                <Dash/>
                            </Route>
                            <Route path="*">
                                404
                            </Route>
                        </Switch>
                    </Router>
                    <ToastContainer/>
                </div>
            </BaseProvider>
        </StyletronProvider>
    );
}

export default App;
