import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import axios from "axios";

const CctldWidget = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const [cctld, setCctld] = React.useState()

    React.useLayoutEffect(() => {
        if (props.id) {
            if (states.cctlds?.length) {
                let c = states.cctlds?.filter(c => c.id === props.id)[0]
                setCctld(c)
            } else {
                var config = {
                    method: 'get',
                    url: 'https://onda.africa/ondarest.php/records/Cctld/' + props.id,
                    headers: {}
                };

                axios(config)
                    .then(function (response) {
                        setCctld(response.data)
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }

    }, [props.id])
    return <span id={'CctldWidget'}>
        <span>{cctld?.intitule}</span>{' '}<span hidden={props.noLink}><a
        href={cctld?.siteWebRegistre}>{cctld?.siteWebRegistre}</a></span>
    </span>;
};

export default CctldWidget;
