import React from 'react';
import './StatNomDomaineCctldListForm.css';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import moment from "moment";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {DatePicker} from "baseui/datepicker";
import countries from "../../../scripts/countries";
import {Select} from "baseui/select";
import {Button} from "baseui/button";
import {create} from "../../../redux/actions";

const StatNomDomaineCctldListForm = (props) => {
	const {t, i18n} = useTranslation();
	const dispatch = useDispatch();
	const states = useSelector(state => state, shallowEqual);
	const {register, handleSubmit, watch, errors, control} = useForm();
	const onSubmit = data => {
		let o = {
			// "id":null,
			"dateEnregistrementNombre": moment(data.dateEnregistrementNombre.date).format('YYYY-MM-DD'),
			"nombreNomDomaine": data.nombreNomDomaine,
			"nombreNationaux": data.nombreNationaux,
			"nombreInternationaux": data.nombreInternationaux,
			"nombrePrive": data.nombrePrive,
			"nombrePublic": data.nombrePublic,
			"nombreAssociation": data.nombreAssociation,
			"Registre": props.registry
		}
		dispatch(create(o,'StatNomDomaineCctld','someStatNomDomaineCctld'))
		props.close(false)
	}

	return (
		<div className="StatNomDomaineCctldListForm">
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormControl label={() => t('dateEnregistrementNombre')}>
					<>
						<Controller
							name="dateEnregistrementNombre"
							control={control}
							rules={{required: true}}
							defaultValue={moment(props.registry?.dateEnregistrementNombre,'YYYY-MM-DD').toDate()}
							render={({onChange, value}) => <DatePicker value={moment(value.date).toDate()} onChange={onChange}/>}
						/>
						{errors.dateEnregistrementNombre && <span>{t('field_required')}</span>}
					</>
				</FormControl>

				<FormControl label={() => t('nombreNomDomaine')}>
					<>
						<Controller
							name="nombreNomDomaine"
							control={control}
							rules={{required: true}}
							defaultValue={props.registry?.nombreNomDomaine}
							render={({onChange, value}) => <Input value={value} type={'number'} onChange={onChange}/>}
						/>
						{errors.nombreNomDomaine && <span>{t('field_required')}</span>}
					</>
				</FormControl>
				<FormControl label={() => t('nombreNationaux')}>
					<>
						<Controller
							name="nombreNationaux"
							control={control}
							rules={{required: true}}
							defaultValue={props.registry?.nombreNationaux}
							render={({onChange, value}) => <Input value={value} type={'number'} onChange={onChange}/>}
						/>
						{errors.nombreNationaux && <span>{t('field_required')}</span>}
					</>
				</FormControl>
				<FormControl label={() => t('nombreInternationaux')}>
					<>
						<Controller
							name="nombreInternationaux"
							control={control}
							rules={{required: true}}
							defaultValue={props.registry?.nombreInternationaux}
							render={({onChange, value}) => <Input value={value} type={'number'} onChange={onChange}/>}
						/>
						{errors.nombreInternationaux && <span>{t('field_required')}</span>}
					</>
				</FormControl>
				<FormControl label={() => t('nombrePrive')}>
					<>
						<Controller
							name="nombrePrive"
							control={control}
							rules={{required: true}}
							defaultValue={props.registry?.nombrePrive}
							render={({onChange, value}) => <Input value={value} type={'number'} onChange={onChange}/>}
						/>
						{errors.nombrePrive && <span>{t('field_required')}</span>}
					</>
				</FormControl>
				<FormControl label={() => t('nombrePublic')}>
					<>
						<Controller
							name="nombrePublic"
							control={control}
							rules={{required: true}}
							defaultValue={props.registry?.nombrePublic}
							render={({onChange, value}) => <Input value={value} type={'number'} onChange={onChange}/>}
						/>
						{errors.nombrePublic && <span>{t('field_required')}</span>}
					</>
				</FormControl>
				<FormControl label={() => t('nombreAssociation')}>
					<>
						<Controller
							name="nombreAssociation"
							control={control}
							rules={{required: true}}
							defaultValue={props.registry?.nombreAssociation}
							render={({onChange, value}) => <Input value={value} type={'number'} onChange={onChange}/>}
						/>
						{errors.nombreAssociation && <span>{t('field_required')}</span>}
					</>
				</FormControl>

				<Button type={'submit'}>{t('enregistrer')}</Button>
			</form>
		</div>
	);
};

export default StatNomDomaineCctldListForm;
