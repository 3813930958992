import React from 'react';
import './StatNomDomaineCctldList.css';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Spinner} from "baseui/spinner";
import {FlexGrid, FlexGridItem} from "baseui/flex-grid";
import {Button} from "baseui/button";
import {Modal, ModalBody, ModalHeader} from "baseui/modal";
import StatNomDomaineCctldListView from "../../views/StatNomDomaineCctldListView";
import StatNomDomaineCctldListForm from "../../forms/StatNomDomaineCctldListForm";
import {getSomeDSNStats} from "../../../redux/actions";

const StatNomDomaineCctldList = (props) => {
	const {t, i18n} = useTranslation();
	const dispatch = useDispatch();
	const states = useSelector(state => state, shallowEqual);
	const [isOpen, setIsOpen] = React.useState(false);
	const history = useHistory()
	React.useLayoutEffect(() => {
		dispatch(getSomeDSNStats(props.registry))
	}, [])
	const [list, setList] = React.useState(undefined);
	React.useLayoutEffect(() => {
		setList(states.someStatNomDomaineCctld)
	}, [states.someStatNomDomaineCctld])


	return (
		<div className="StatNomDomaineCctldList">
			 {/*StatNomDomaineCctldList*/}
			{!list && <Spinner color={'#fe9957'}/>}
			{list?.length===0 && <div>{t('no_data_yet')}</div>}
			<FlexGrid
				flexGridColumnCount={[1, 1, 2, 2]}
				flexGridColumnGap="scale800"
				flexGridRowGap="scale800"
			>
				{list?.map(d => <FlexGridItem>
					<StatNomDomaineCctldListView data={d}/>
				</FlexGridItem>)}
			</FlexGrid>
			<p>
				{states.utilisateur?.id === states.registry?.Utilisateur && <Button onClick={e=>setIsOpen(true)}>{t('add_StatNomDomaineCctld')}</Button>}
			</p>

			<Modal
				onClose={() => setIsOpen(false)}
				closeable
				isOpen={isOpen}
				animate
				autoFocus
			>
				<ModalHeader/>
				<ModalBody>
					<StatNomDomaineCctldListForm registry={props.registry} close={setIsOpen}/>
				</ModalBody>
			</Modal>
		</div>
	);
};

export default StatNomDomaineCctldList;
