import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import moment from "moment";
import {create} from "../../../redux/actions";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {Button} from "baseui/button";
import {Radio, RadioGroup} from "baseui/radio";
import {Select} from "baseui/select";
import {Checkbox} from "baseui/checkbox";
import countries from "../../../scripts/countries";

const BusinessModelForm = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const {register, handleSubmit, watch, errors, control} = useForm();
    const onSubmit = data => {
        let o = {
            "intitule": data.intitule,
            "politiqueTarifaire": data.politiqueTarifaire || 0,
            "tarifEnregistrement": data.tarifEnregistrement || 0,
            "tarifSuppression": data.tarifSuppression || 0,
            "tarifModification": data.tarifModification || 0,
            "tarifTransfert": data.tarifTransfert || 0,
            "tarifRenouvellement": data.tarifRenouvellement,
            "processusVenteEnchere": data.processusVenteEnchere,
            "processusVentePremium": data.processusVentePremium,
            "processusVentePublicite": data.processusVentePublicite,
            "monopoleVenteDirect": data.monopoleVenteDirect,
            "date": moment().format('YYYY-MM-DD'),
            "Registre": Number(props.registry),
            "Categorie": data.Categorie.option.id
        }
        dispatch(create(o, 'BusinessModel', 'someBusinessModels'))
        // console.log(o)
        props.close(false)
    }
    const [politiqueTarifaire, setpolitiqueTarifaire] = React.useState(false)

    return <div id={'BusinessModelForm'}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl label={() => t('intitule')}>
                <>
                    <Controller
                        name="intitule"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input onChange={onChange}/>}
                    />
                    {errors.intitule && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('Categorie')}>
                <>
                    <Controller
                        name="Categorie"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Select
                            options={states.categories?.map(c=> {
                                return  {label:c.intitule, id:c.id}
                            })}
                            value={value.value}
                            placeholder="Select"
                            onChange={onChange}
                        />}
                    />
                    {errors.Categorie && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => ''}>
                <>
                    <Controller
                        name="politiqueTarifaire"
                        control={control}
                        // rules={{required: true}}
                        defaultValue={false}
                        render={({onChange, value}) => <Checkbox
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked)
                                setpolitiqueTarifaire(e.target.checked)
                            }}
                            labelPlacement={"right"}
                            value={true}
                        >
                            {t('politiqueTarifaire')}
                        </Checkbox>}
                    />
                    {errors.politiqueTarifaire && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <div style={{display:politiqueTarifaire?'block':'none'}}>
                <FormControl label={() => t('tarifEnregistrement')}>
                    <>
                        <Controller
                            name="tarifEnregistrement"
                            control={control}
                            rules={{required: true}}
                            defaultValue={0}
                            render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
                        />
                        {errors.tarifEnregistrement && <span>{t('field_required')}</span>}
                    </>
                </FormControl>
                <FormControl label={() => t('tarifSuppression')}>
                    <>
                        <Controller
                            name="tarifSuppression"
                            control={control}
                            rules={{required: true}}
                            defaultValue={0}
                            render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
                        />
                        {errors.tarifSuppression && <span>{t('field_required')}</span>}
                    </>
                </FormControl>
                <FormControl label={() => t('tarifModification')}>
                    <>
                        <Controller
                            name="tarifModification"
                            control={control}
                            rules={{required: true}}
                            defaultValue={0}
                            render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
                        />
                        {errors.tarifModification && <span>{t('field_required')}</span>}
                    </>
                </FormControl>
                <FormControl label={() => t('tarifTransfert')}>
                    <>
                        <Controller
                            name="tarifTransfert"
                            control={control}
                            rules={{required: true}}
                            defaultValue={0}
                            render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
                        />
                        {errors.tarifTransfert && <span>{t('field_required')}</span>}
                    </>
                </FormControl>
                <FormControl label={() => t('tarifRenouvellement')}>
                    <>
                        <Controller
                            name="tarifRenouvellement"
                            control={control}
                            rules={{required: true}}
                            defaultValue={0}
                            render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
                        />
                        {errors.tarifRenouvellement && <span>{t('field_required')}</span>}
                    </>
                </FormControl>
            </div>
            <FormControl label={() => ''}>
                <>
                    <Controller
                        name="processusVenteEnchere"
                        control={control}
                        // rules={{required: true}}
                        defaultValue={false}
                        render={({onChange, value}) => <Checkbox
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked)
                            }}
                            labelPlacement={"right"}
                            value={true}
                        >
                            {t('processusVenteEnchere')}
                        </Checkbox>}
                    />
                    {errors.processusVenteEnchere && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => ''}>
                <>
                    <Controller
                        name="processusVentePremium"
                        control={control}
                        // rules={{required: true}}
                        defaultValue={false}
                        render={({onChange, value}) => <Checkbox
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked)
                            }}
                            labelPlacement={"right"}
                            value={true}
                        >
                            {t('processusVentePremium')}
                        </Checkbox>}
                    />
                    {errors.processusVentePremium && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => ''}>
                <>
                    <Controller
                        name="processusVentePublicite"
                        control={control}
                        // rules={{required: true}}
                        defaultValue={false}
                        render={({onChange, value}) => <Checkbox
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked)
                            }}
                            labelPlacement={"right"}
                            value={true}
                        >
                            {t('processusVentePublicite')}
                        </Checkbox>}
                    />
                    {errors.processusVentePublicite && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => ''}>
                <>
                    <Controller
                        name="monopoleVenteDirect"
                        control={control}
                        // rules={{required: true}}
                        defaultValue={false}
                        render={({onChange, value}) => <Checkbox
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked)
                            }}
                            labelPlacement={"right"}
                            value={true}
                        >
                            {t('monopoleVenteDirect')}
                        </Checkbox>}
                    />
                    {errors.monopoleVenteDirect && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <Button type={'submit'}>{t('enregistrer')}</Button>
        </form>
    </div>;
};

export default BusinessModelForm;
