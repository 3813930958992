import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getAllRegistry, getUsers} from "../../../redux/actions";
import {Button, SHAPE, SIZE, KIND} from "baseui/button";
import DataTable from 'react-data-table-component';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    ROLE
} from "baseui/modal";
import RegistryForm from "../../forms/RegistryForm";
import {useHistory} from "react-router-dom";
import CctldWidget from "../../widgets/CctldWidget";
import SectionTitle from "../../widgets/SectionTitle";
import CountryWidget from "../../widgets/CountryWidget";
import {Tag} from "baseui/tag";
import {FlexGrid, FlexGridItem} from "baseui/flex-grid";
import RegistreViewWidget from "../../widgets/RegistreViewWidget";
import {Spinner} from "baseui/spinner";

const RegistryList = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const [isOpen, setIsOpen] = React.useState(false);
    const [list, setList] = React.useState(undefined);
    const history = useHistory()

    React.useEffect(() => {
        setList(states.registries)
        dispatch({type: "ONE_REGISTRY", key: 'registry', payload: undefined});
    }, [states.registries])

    // const columns = React.useMemo(() => [
    //     {
    //         name: t('nomRegistre'),
    //         selector: 'nomRegistre',
    //         sortable: true,
    //     },
    //     {
    //         name: t('pays'),
    //         selector: 'pays',
    //         sortable: true,
    //         cell: row => <CountryWidget code={row.pays}/>
    //     },
    //     {
    //         name: t('Cctld'),
    //         selector: 'Cctld',
    //         sortable: true,
    //         cell: row => <CctldWidget id={row.Cctld} noLink/>
    //     },
    //     {
    //         name: t('status'),
    //         selector: 'status',
    //         sortable: true,
    //         cell: row => row.status === 'true' ?  <Tag closeable={false} variant={"solid"} kind={'green'}>
    //             &nbsp;
    //             &nbsp;
    //         </Tag> : <Tag closeable={false} variant={"solid"} kind={'red'}>
    //             &nbsp;
    //             &nbsp;
    //         </Tag>
    //     },
    //     {
    //         name: t('action'),
    //         selector: 'email',
    //         sortable: true,
    //         right: true,
    //         cell: row => <div>
    //             {/*<Button*/}
    //             {/*    onClick={() => alert("click")}*/}
    //             {/*    size={SIZE.mini}*/}
    //             {/*>*/}
    //             {/*    deactiver*/}
    //             {/*</Button>*/}
    //             {/*{' '}*/}
    //             {/*<Button*/}
    //             {/*    onClick={() => alert("click")}*/}
    //             {/*    size={SIZE.mini}*/}
    //             {/*>*/}
    //             {/*    activer*/}
    //             {/*</Button>*/}
    //             <Button
    //                 onClick={e=>rowCliqued(row)}
    //                 size={SIZE.mini}
    //             >
    //                 {t('details')}
    //             </Button>
    //         </div>
    //     }
    // ], [states]);

    const rowCliqued = (r) => {
        history.push('/dash/registry/' + r.id)
        // console.log(r)
    }
    return <div id={'RegistryList'}>
        <div style={{display:"flex",justifyContent:"space-between",paddingTop:15, alignItems:"center"}}>
            <SectionTitle title={t('registry_list')}/>
            <div>
                {states.utilisateur?.Role<=2 && <Button
                    onClick={() => setIsOpen(true)}>
                    {t('add_Registry')}
                </Button>}
            </div>
        </div>
        <div style={{marginTop:15}}>
            {!list && <Spinner color={'#fe9957'}/>}
            {list?.length===0 && <div>{t('no_data_yet')}</div>}
            <FlexGrid
                flexGridColumnCount={[1, 1, 2, 3]}
                flexGridColumnGap="scale800"
                flexGridRowGap="scale800"
            >
                {list?.map(d => <FlexGridItem>
                    <RegistreViewWidget data={d}/>
                </FlexGridItem>)}
            </FlexGrid>
        </div>
        {/*<DataTable*/}
        {/*    columns={columns}*/}
        {/*    data={states.registries}*/}
        {/*    highlightOnHover*/}
        {/*    pointerOnHover*/}
        {/*    onRowClicked={rowCliqued}*/}
        {/*    noHeader*/}
        {/*/>*/}
        <div>
            <Modal
                onClose={() => setIsOpen(false)}
                closeable
                isOpen={isOpen}
                animate
                autoFocus
                size={SIZE.default}
                role={ROLE.dialog}
            >
                <ModalHeader></ModalHeader>
                <ModalBody>
                    <RegistryForm close={setIsOpen}/>
                </ModalBody>
                {/*<ModalFooter></ModalFooter>*/}
            </Modal>
        </div>
    </div>;
};

export default RegistryList;
