import React from 'react';
import "./EreputationView.css"
import {useTranslation} from "react-i18next";
const EreputationView = (props) => {
  const {t, i18n} = useTranslation();
  return <div className={'EreputationView'}>
    <div><span className={'text-muted'}>{t('nombreCampagneCommunication')}:</span>{props.data.nombreCampagneCommunication}</div>
    <div><span className={'text-muted'}>{t('pageFacebook')}:</span>{props.data.pageFacebook?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('pageInstagram')}:</span>{props.data.pageInstagram?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('pageLinkedIn')}:</span>{props.data.pageLinkedIn?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('pageTwitter')}:</span>{props.data.pageTwitter?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('politiqueCommunication')}:</span>{props.data.politiqueCommunication?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('politiqueMarketing')}:</span>{props.data.politiqueMarketing?t('yes'):t('no')}</div>
  </div>;
};

export default EreputationView;
