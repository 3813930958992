import React from 'react';
import {Input} from "baseui/input";
import {Button, KIND, SHAPE} from "baseui/button";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {toaster, ToasterContainer} from "baseui/toast";
import Cookies from 'universal-cookie';
import {useHistory} from "react-router-dom";

const SignInForm = () => {
    const cookies = new Cookies();
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const history = useHistory()
    const [email, setEmail] = React.useState()
    const [pass, setPass] = React.useState()
    const signin = () => {
        dispatch({type: 'RESET'})
        dispatch({type: 'SET', key: 'authLoading', payload: true})
        if (email && pass) {
            let data = JSON.stringify({"username": email, "password": pass});

            let config = {
                method: 'post',
                url: 'https://onda.africa/ondarest.php/login',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    if (response.data.id) {
                        const msg = t('successfull_login')
                        toaster.show(<>{msg}</>, {
                            onClose: () => {
                                localStorage.setItem('ondaUser', response.data.id)
                                localStorage.setItem('ondaUserToken', response.data.token)
                                localStorage.setItem('ondaUserEmail', response.data.username)
                                cookies.set('PHPSESSID', response.data.token, {path: '/'});
                                history.push('/dash')
                            }
                        });
                        setPass(null)
                        setEmail(null)
                        // console.log(response)
                        // setTimeout(()=>props.setForm('signIn'),3500)
                    }
                })
                .catch(function (error) {
                    dispatch({type: 'SET', key: 'authLoading', payload: false})
                    toaster.negative(<>{error.response?.data.message}</>, {});
                });
        }
    }
    return <div id={'SignInForm'}>
        <p><Input placeholder={t('email')} type={'email'} onChange={e => setEmail(e.target.value)}/></p>
        <p><Input placeholder={t('password')} type={'password'} onChange={e => setPass(e.target.value)}/></p>
        <p>
            <Button isLoading={states.authLoading} onClick={signin} shape={SHAPE.pill}>{t('login')}</Button>
            {' '}
            {/*<Button kind={KIND.secondary} shape={SHAPE.pill}>{t('cancel')}</Button>*/}
        </p>
        <ToasterContainer autoHideDuration={3000}/>
    </div>;
};

export default SignInForm;
