import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import axios from "axios";

const LegalStatusWidget = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const [status, setStatus] = React.useState()

    React.useLayoutEffect(() => {
        if (props.id){
            var config = {
                method: 'get',
                url: 'https://onda.africa/ondarest.php/records/StatutJuridique/' + props.id,
                headers: {}
            };

            axios(config)
                .then(function (response) {
                    setStatus(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [props.id])
    return <span id={'LegalStatusWidget'}>
        {status?.libelle}
    </span>;
};

export default LegalStatusWidget;
