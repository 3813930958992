import React from 'react';
import {Card, StyledAction, StyledBody} from "baseui/card";
import {Button} from "baseui/button";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import CountryWidget from "../CountryWidget";

const RegistreViewWidget = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const history = useHistory()
  return <div className={'RegistreViewWidget'}>
    <Card>
      <StyledBody>
        <h3>{props.data.nomRegistre}</h3>
        <div><CountryWidget code={props.data.pays}/></div>
        <small>{t('dateCreation')}: <span>{props.data.dateCreation}</span></small>
        <div hidden={!props.data.website}><small>{t('website')}<a href={props.data.website}>{props.data.website}</a></small></div>
      </StyledBody>
      <StyledAction>
        <Button
            overrides={{
              BaseButton: { style: { width: "100%" } }
            }}
            onClick={e=>history.push('/dash/registry/' + props.data.id)}
        >
          {t('view_detail')}
        </Button>
      </StyledAction>
    </Card>
  </div>;
};

export default RegistreViewWidget;
