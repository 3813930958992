import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import moment from "moment";
import {create, setInfo} from "../../../redux/actions";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {Button} from "baseui/button";
import {Select} from "baseui/select";
import countries from "../../../scripts/countries";

const UtilisateurForm = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const {register, handleSubmit, watch, errors, control} = useForm();
    const onSubmit = data => {
        let o = {
            "nom": data.nom,
            "prenom": data.prenom,
            "sexe": data.sexe.option.id,
            "pays": data.pays.option.id,
            "contact": data.contact,
            "email": states.me.username,
            "Role": 6,
            "user": states.me.id,
            "status": "CREATED"
        }
        dispatch(setInfo(o, 'Utilisateur'))
        props.close(false)
    }
    return <div id={'UtilisateurForm'}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl label={() => t('nom')}>
                <>
                    <Controller
                        name="nom"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input onChange={onChange}/>}
                    />
                    {errors.nom && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('prenom')}>
                <>
                    <Controller
                        name="prenom"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input onChange={onChange}/>}
                    />
                    {errors.prenom && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('contact')}>
                <>
                    <Controller
                        name="contact"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input onChange={onChange}/>}
                    />
                    {errors.contact && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('sexe')}>
                <>
                    <Controller
                        name="sexe"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Select
                            options={[{label:t('man'),id:'man'},{label:t('woman'),id:'woman'},{label:t('other'),id:'other'}]}
                            value={value.value}
                            placeholder="Select"
                            onChange={onChange}
                        />}
                    />
                    {errors.sexe && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('pays')}>
                <>
                    <Controller
                        name="pays"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Select
                            options={countries.filter(c=>c.region.toLowerCase()==='africa').map(c=> {
                                return  {label:c.translations[i18n.language]||c.name, id:c.alpha2Code,object:c}
                            })}
                            value={value.value}
                            placeholder="Select"
                            onChange={onChange}
                        />}
                    />
                    {errors.pays && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <Button type={'submit'}>{t('enregistrer')}</Button>
        </form>
    </div>;
};

export default UtilisateurForm;
