import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import moment from "moment";
import {create} from "../../../redux/actions";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {Button} from "baseui/button";

const ServicePlusForm = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const {register, handleSubmit, watch, errors, control} = useForm();
  const onSubmit = data => {
    let o = {
      "libelle": data.libelle,
      "date": moment().format('YYYY-MM-DD'),
      "Registre": props.registry
    }
    dispatch(create(o,'ServiceAdditionnel','someServicePlus'))
    props.close(false)
  }
  return <div id={'ServicePlusForm'}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl label={() => t('libelle')}>
        <>
          <Controller
              name="libelle"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input onChange={onChange}/>}
          />
          {errors.libelle && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <Button type={'submit'}>{t('enregistrer')}</Button>
    </form>
  </div>;
};

export default ServicePlusForm;
