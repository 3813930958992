import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import moment from "moment";
import {create} from "../../../redux/actions";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {Button} from "baseui/button";

const BudgetExerciceForm = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const {register, handleSubmit, watch, errors, control} = useForm();
  const onSubmit = data => {
    let o = {
      // "id": "<integer>",
      "annee": data.annee,
      "budgetFonctionnement": data.budgetFonctionnement,
      "resultatAnneePrecedente": data.resultatAnneePrecedente,
      "origineRessourceFinanciere": "<large-string>",
      "impactCovid": "<large-string>",
      "Registre": props.registry
    }
    dispatch(create(o,'ExerciceBudgetaire','someBudgetExercices'))
    props.close(false)
  }
  return <div id={'BudgetExerciceForm'}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl label={() => t('annee')}>
        <>
          <Controller
              name="annee"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
          />
          {errors.annee && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('origineRessourceFinanciere')}>
        <>
          <Controller
              name="origineRessourceFinanciere"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input onChange={onChange}/>}
          />
          {errors.origineRessourceFinanciere && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('impactCovid')}>
        <>
          <Controller
              name="impactCovid"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input onChange={onChange}/>}
          />
          {errors.impactCovid && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('budgetFonctionnement')}>
        <>
          <Controller
              name="budgetFonctionnement"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
          />
          {errors.budgetFonctionnement && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('resultatAnneePrecedente')}>
        <>
          <Controller
              name="resultatAnneePrecedente"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
          />
          {errors.resultatAnneePrecedente && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <Button type={'submit'}>{t('enregistrer')}</Button>
    </form>
  </div>;
};

export default BudgetExerciceForm;
