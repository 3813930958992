import React from 'react';
import "./BudgetExerciceView.css"
import {useTranslation} from "react-i18next";

const BudgetExerciceView = (props) => {
  const {t, i18n} = useTranslation();
  return <div className={'BudgetExerciceView'}>
    <div><span className={'text-muted'}>{t('annee')}:</span>{props.data.annee}</div>
    <div><span className={'text-muted'}>{t('budgetFonctionnement')}:</span>{props.data.budgetFonctionnement}</div>
    <div><span className={'text-muted'}>{t('impactCovid')}:</span><div>{props.data.impactCovid}</div></div>
    <div><span className={'text-muted'}>{t('origineRessourceFinanciere')}:</span>{props.data.origineRessourceFinanciere}</div>
    <div><span className={'text-muted'}>{t('resultatAnneePrecedente')}:</span>{props.data.resultatAnneePrecedente}</div>
  </div>;
};

export default BudgetExerciceView;
