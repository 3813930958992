import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

const RegistreWidget = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const [registre,setRegistre] = React.useState({})

  React.useEffect(()=>{
    if (states.registries?.length){
      let r = states.registries?.filter(r=>r.id === Number(props.id))[0]
      setRegistre(r)
    }else {

    }
  },[props.id])
  if (props.span) return <span>{registre?.nomRegistre}</span>;
  return <div>{registre?.nomRegistre}</div>;
};

export default RegistreWidget;
