import React from 'react';
import {FormControl} from "baseui/form-control";
import {Controller, useForm} from "react-hook-form";
import {Input} from "baseui/input";
import {Button} from "baseui/button";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {create} from "../../../redux/actions";

const AccreditationProcessForm = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const {register, handleSubmit, watch, errors, control} = useForm();
  const onSubmit = data => {
    let o = {
      "nature": data.nature,
      "nombreRegistrarAgree": data.nombreRegistrarAgree,
      "nombreRegistrarNationaux": data.nombreRegistrarNationaux,
      "nombreRegistrarInternationaux": data.nombreRegistrarInternationaux,
      "nombreAccrediteIcann": data.nombreAccrediteIcann,
      "date": moment().format('YYYY-MM-DD'),
      "Registre": Number(props.registry)
    }
    dispatch(create(o,'ProcedureAccreditation','someAccreditationProcess'))
    props.close(false)
  }
  return <div id={'AccreditationProcessForm'}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl label={() => t('nature')}>
        <>
          <Controller
              name="nature"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input onChange={onChange}/>}
          />
          {errors.nature && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('nombreRegistrarAgree')}>
        <>
          <Controller
              name="nombreRegistrarAgree"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
          />
          {errors.nombreRegistrarAgree && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('nombreRegistrarNationaux')}>
        <>
          <Controller
              name="nombreRegistrarNationaux"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
          />
          {errors.nombreRegistrarNationaux && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('nombreRegistrarInternationaux')}>
        <>
          <Controller
              name="nombreRegistrarInternationaux"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
          />
          {errors.nombreRegistrarInternationaux && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('nombreAccrediteIcann')}>
        <>
          <Controller
              name="nombreAccrediteIcann"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
          />
          {errors.nombreAccrediteIcann && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <Button type={'submit'}>{t('enregistrer')}</Button>
    </form></div>;
};

export default AccreditationProcessForm;
