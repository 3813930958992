import React from 'react';
import "./AutomatProcessView.css"
import {useTranslation} from "react-i18next";

const AutomatProcessView = (props) => {
  const {t, i18n} = useTranslation();
  return <div className={'AutomatProcessView'}>
    <div><span className={'text-muted'}>{t('dureeTotale')}:</span>{props.data.dureeTotale}</div>
    <div><span className={'text-muted'}>{t('existenceOutilRecherche')}:</span>{props.data.existenceOutilRecherche?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('existenceServiceWhois')}:</span>{props.data.existenceServiceWhois?t('yes'):t('no')}</div>
  </div>;
};

export default AutomatProcessView;
