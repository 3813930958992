import React from 'react';
import {useTranslation} from "react-i18next";

const SectionTitle = (props) => {
  const {t, i18n} = useTranslation();
  return <div id={'SectionTitle'}>
    <h2>{t(props.title)}</h2>
  </div>;
};

export default SectionTitle;
