import React from 'react';
import './StatNomDomaineCctldListView.css';
import {useTranslation} from "react-i18next";
import moment from "moment";

const StatNomDomaineCctldListView = (props) => {
	const {t, i18n} = useTranslation();
	return (
		<div className="StatNomDomaineCctldListView">
			 <div>
				 <span className={'text-muted'}>{t('dateEnregistrementNombre')}:</span> {moment(props.data?.dateEnregistrementNombre).format('DD/MM/YYYY')}
			 </div>
			<div>
				<strong>{t('nombreEnreg')}</strong>
			</div>
			 <div>
				 <span className={'text-muted'}>{t('nombreNomDomaine')}:</span> {props.data?.nombreNomDomaine}
			 </div>
			 <div>
				 <span className={'text-muted'}>{t('nombreNationaux')}:</span> {props.data?.nombreNationaux}
			 </div>
			 <div>
				 <span className={'text-muted'}>{t('nombreInternationaux')}:</span> {props.data?.nombreInternationaux}
			 </div>
			 <div>
				 <span className={'text-muted'}>{t('nombrePrive')}:</span> {props.data?.nombrePrive}
			 </div>
			 <div>
				 <span className={'text-muted'}>{t('nombrePublic')}:</span> {props.data?.nombrePublic}
			 </div>
			 <div>
				 <span className={'text-muted'}>{t('nombreAssociation')}:</span> {props.data?.nombreAssociation}
			 </div>
		</div>
	);
};

export default StatNomDomaineCctldListView;
