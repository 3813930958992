import React from 'react';
import {Layer} from "baseui/layer";
import {AppNavBar, setItemActive} from "baseui/app-nav-bar";
import {useStyletron} from "baseui";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import CountryWidget from "../widgets/CountryWidget";

const DashHeader = () => {
    const {t, i18n} = useTranslation();
    const states = useSelector(state => state, shallowEqual);
    const [css] = useStyletron();
    const history = useHistory()
    const [user, setUser] = React.useState({})
    const [mainItems, setMainItems] = React.useState([
        {label: t("home"),route:'/dash'},
        // {label: t("visualisation"),route:'/dash/visualisation'}
    ]);
    const Title = () => <div>ONDA</div>

    React.useEffect(()=>{
        // console.log(window.outerWidth)
    },[window.onresize])
    React.useEffect(()=>{
        setUser(states.utilisateur)
    },[states.utilisateur,states.me])
    const getOut = () => {
        let config = {
            method: 'post',
            url: 'https://onda.africa/ondarest.php/logout',
            headers: {
                'Content-Type': 'application/json',
            }
        };
        axios(config).then(function (response) {
            localStorage.removeItem('ondaUser')
            localStorage.removeItem('ondaEmail')
            localStorage.removeItem('ondaToken')
            history.push('/')
        })
    }
    return <>
        <Layer>
            <div
                className={css({
                    boxSizing: 'border-box',
                    width: '100vw',
                    position: 'fixed',
                    top: '0',
                    left: '0',
                })}
                id={'DashHeader'}
            >
                <AppNavBar
                    title={<Title/>}
                    mainItems={mainItems}
                    onMainItemSelect={item => {
                        setMainItems(prev => setItemActive(prev, item));
                        history.push(item.route)
                    }}
                    username={user?.prenom + ' ' + user?.nom}
                    usernameSubtitle={<CountryWidget code={user?.pays}/>}
                    userItems={[
                        // {label: t('my_account'),action:'dash'},
                        {label: t('logout'),action:'out'}
                    ]}
                    onUserItemSelect={item => {
                        if (item.action==='out'){
                            getOut()
                        }
                        if (item.action==='dash'){
                            history.push('/dash')
                        }
                    }}
                />
            </div>
        </Layer>
        <div style={{height: 74}}/>
    </>;
};

export default DashHeader;
