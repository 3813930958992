import React from 'react';
import "./NameChartView.css"
import {useTranslation} from "react-i18next";
const NameChartView = (props) => {
  const {t, i18n} = useTranslation();
  return <div className={'NameChartView'}>
    <div><span className={'text-muted'}>{t('nature')}:</span> {props.data.nature}</div>
    <div><span className={'text-muted'}>{t('accessibiliteEtranger')}:</span> {props.data.accessibiliteEtranger?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('accessibiliteNationaux')}:</span> {props.data.accessibiliteNationaux?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('accessibilitePersonneMorale')}:</span> {props.data.accessibilitePersonneMorale?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('accessibilitePersonnePhysique')}:</span> {props.data.accessibilitePersonnePhysique?t('yes'):t('no')}</div>
  </div>;
};

export default NameChartView;
