import React from 'react';
import {Navigation} from "baseui/side-navigation";
import {useHistory, useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {shallowEqual, useSelector} from "react-redux";

const DashLeft = () => {
    const history = useHistory()
    const location = useLocation()
    const states = useSelector(state => state, shallowEqual);
    const {t, i18n} = useTranslation();
    const [activeItemId, setActiveItemId] = React.useState(
        "#primary"
    );
    const registrMenu = [
        {
            title: t('menu_dash'),
            itemId: "/dash",
        },
        {
            title: t('menu_registry'),
            itemId: "/dash/registries",
        },
        {
            title: t('menu_registrar'),
            itemId: "/dash/registrars",
        },
        {
            title: t('menu_stats'),
            itemId: "/dash/stats",
        }
    ]
    const analystMenu = []
    const adminMenu = [
        {
            title: t('menu_dash'),
            itemId: "/dash",
        },
        {
            title: t('menu_registry'),
            itemId: "/dash/registries",
        },
        {
            title: t('menu_registrar'),
            itemId: "/dash/registrars",
        },
        {
            title: t('menu_stats'),
            itemId: "/dash/stats",
        },
        // {
        //     title: t('menu_publications'),
        //     itemId: "/dash/publications",
        // },
        {
            title: t('menu_users'),
            itemId: "/dash/users",
        },
    ]

    // console.log(location)
    return <div id={'DashLeft'}>
        <Navigation
            items={states.utilisateur?.Role===1?adminMenu:registrMenu}
            activeItemId={location.pathname}
            onChange={({item, event}) => {
                event.preventDefault()
                event.stopPropagation()
                setActiveItemId(item.itemId)
                history.push(item.itemId)
            }
            }
        />
    </div>;
};

export default DashLeft;
