import React from 'react';
import "./GouvCctldView.css"
import {useTranslation} from "react-i18next";
import CountryWidget from "../../widgets/CountryWidget";
import RegistreWidget from "../../widgets/RegistreWidget";

const GouvCctldView = (props) => {
    const {t, i18n} = useTranslation();
    return <div className={'GouvCctldView'}>
        <div style={{fontSize: 20, fontWeight: "bold"}}>{props.data.labelle}</div>
        <div><span className={'text-muted'}>{t('cadreLegal')}:</span>  {props.data.cadreLegal?t('yes'):t('no')}</div>
        <div><span className={'text-muted'}>{t('dateCreationRegistreTechnique')}:</span>  {props.data.dateCreationRegistreTechnique}</div>
        <div><span className={'text-muted'}>{t('duree')}:</span>  {props.data.duree}</div>
        <div><span className={'text-muted'}>{t('projetRevision')}:</span>  {props.data.projetRevision?t('yes'):t('no')}</div>
        <div><span className={'text-muted'}>{t('registreTechnique')}:</span>  <RegistreWidget span id={props.data.registreTechnique}/></div>
        <div><span className={'text-muted'}>{t('paysRegistreTechnique')}:</span>  <CountryWidget span code={props.data.paysRegistreTechnique}/></div>
        <div><span className={'text-muted'}>{t('situationGeographiqueRegistreTechnique')}:</span>  {props.data.situationGeographiqueRegistreTechnique}</div>
        <div><span className={'text-muted'}>{t('impactCovid')}:</span>  <div>{props.data.impactCovid}</div></div>
        <div><span className={'text-muted'}>{t('commentaire')}:</span>  <div>{props.data.commentaire}</div></div>

    </div>;
};

export default GouvCctldView;
