import React from 'react';
import {Input} from "baseui/input";
import {Button, KIND, SHAPE} from "baseui/button";
import axios from "axios";
import {toaster, ToasterContainer} from "baseui/toast";
import {Block} from "baseui/block";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";

const SignUpForm = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const [email, setEmail] = React.useState()
    const [pass, setPass] = React.useState()

    const signup = () => {
        dispatch({type:'SET',key:'authLoading',payload:true})
        if (email && pass){
            let data = JSON.stringify({"username": email, "password": pass});

            let config = {
                method: 'post',
                url: 'https://onda.africa/ondarest.php/register',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    if (response.data.id) {
                        const msg = t('successfull_register')
                        toaster.show(<>{msg}</>,{onClose: () => props.setForm('signIn')});
                        setPass(null)
                        setEmail(null)
                    }
                })
                .catch(function (error) {
                    dispatch({type:'SET',key:'authLoading',payload:false})
                    toaster.negative(<>{error.response.data.message}</>,{});
                });
        }
    }
    return <div id={'SignUpForm'}>
        <p>
            <Input placeholder={t('email')} type={'email'} onChange={e => setEmail(e.target.value)}/></p>
        <p>
            <Input placeholder={t('password')} type={'password'} onChange={e => setPass(e.target.value)}/></p>
        <p>
            <Button isLoading={states.authLoading} onClick={signup} shape={SHAPE.pill}>{t('register')}</Button>
            {' '}
            {/*<Button kind={KIND.secondary} shape={SHAPE.pill}>{t('cancel')}</Button>*/}
        </p>
        <ToasterContainer autoHideDuration={3000}/>
    </div>;
};

export default SignUpForm;
