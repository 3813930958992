import React from 'react';
import countries from "../../../scripts/countries";
import lodash from "lodash"
import {useTranslation} from "react-i18next";

const CountryWidget = (props) => {
  const {t, i18n} = useTranslation();
  const [country,setCountry] = React.useState()
  const [name,setNAme] = React.useState()
  React.useLayoutEffect(()=>{
    setCountry(countries.filter(c=>c.alpha2Code===props.code)[0])
  },[props.code])

  if (props.span) return <span id={'CountryWidget'}><img hidden={!country?.flag} src={country?.flag} alt="flag" height={12}/> {country?.translations[i18n.language] || country?.name}</span>;
  return <span id={'CountryWidget'}><img hidden={!country?.flag} src={country?.flag} alt="flag" height={12}/> {country?.translations[i18n.language] || country?.name}</span>;
};

export default CountryWidget;
