import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory,useParams} from "react-router-dom";
import {getOneRegistry} from "../../../redux/actions";
import {Button, SIZE} from "baseui/button";
import CountryWidget from "../../widgets/CountryWidget";
import TypeModelOrgWidget from "../../widgets/TypeModelOrgWidget";
import LegalStatusWidget from "../../widgets/LegalStatusWidget";
import CctldWidget from "../../widgets/CctldWidget";
import RegistryIndicatorsView from "../RegistryIndicatorsView";
import SectionTitle from "../../widgets/SectionTitle";
import DirectionView from "../DirectionView";
import {Modal, ModalBody, ModalHeader, ROLE} from "baseui/modal";
import RegistryForm from "../../forms/RegistryForm";
import {Spinner} from "baseui/spinner";

const RegistryView = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const [isOpen, setIsOpen] = React.useState(false);
  const history = useHistory()
  const params = useParams()

  React.useEffect(()=>{
    dispatch({type: "ONE_REGISTRY", key: 'registry', payload: undefined});
    dispatch(getOneRegistry(params.id))
  },[])

  return <div id={'RegistryView'}>
    <SectionTitle title={states.registry?.nomRegistre}/>
    {/*<h2>{states.registry?.nomRegistre}</h2>*/}
    <div style={{display:"flex",justifyContent:"space-between"}}>
      {!states.registry?.id && <Spinner color={'#fe9957'}/>}
      <div hidden={!states.registry?.id}>
        <div><strong>{t('pays')}:</strong> <CountryWidget code={states.registry?.pays}/></div>
        <div><strong>{t('TypeModelOrg')}:</strong> <TypeModelOrgWidget id={states.registry?.TypeModelOrg}/></div>
        <div><strong>{t('StatutJuridique')}:</strong> <LegalStatusWidget id={states.registry?.StatutJuridique}/></div>
        <div><strong>{t('Cctld')}:</strong> <CctldWidget id={states.registry?.Cctld} /></div>
        {/*<div>Utilisateur</div>*/}
        <div><strong>{t('situationGeographique')}:</strong> {states.registry?.situationGeographique}</div>
      </div>
      <div>
      </div>
      <div>
        {/*<Button>activate</Button>{' '}*/}
        {/*<Button>{t('deactivate')}</Button>{' '}*/}
        {states.utilisateur?.id === states.registry?.Utilisateur &&
        <Button onClick={e=>setIsOpen(true)}>{t('edit')}</Button>}
        {' '}
        <Button
            onClick={() => history.push('/dash/registries')}>
          {t('back')}
        </Button>
      </div>
    </div>

    <div style={{marginTop:32}}>
      <RegistryIndicatorsView registry={params.id}/>
    </div>
    {/*<hr/>*/}
    <p>
      {/*<Button onClick={e=>history.goBack()}>back</Button>*/}
    </p>
    <div>
      <Modal
          onClose={() => setIsOpen(false)}
          closeable
          isOpen={isOpen}
          animate
          autoFocus
          size={SIZE.default}
          role={ROLE.dialog}
      >
        <ModalHeader></ModalHeader>
        <ModalBody>
          <RegistryForm close={setIsOpen} registry={states.registry}/>
        </ModalBody>
        {/*<ModalFooter></ModalFooter>*/}
      </Modal>
    </div>
  </div>;
};

export default RegistryView;
