import React from 'react';
import {useForm, Controller} from "react-hook-form";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {DatePicker} from "baseui/datepicker";
import {Select} from "baseui/select";
import {Button, KIND} from "baseui/button";
import countries from "../../../scripts/countries";
import {editRegistry, insertRegistry} from "../../../redux/actions";
import moment from "moment";

const RegistryForm = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const {register, handleSubmit, watch, errors, control} = useForm();
    const onSubmit = data => {
        let o = {
            // "id":null,
            "nomRegistre": data.nomRegistre || props.registry?.nomRegistre,
            "dateCreation": moment(data.dateCreation.date).format('YYYY-MM-DD') || props.registry?.dateCreation,
            "situationGeographique": data.situationGeographique || props.registry?.situationGeographique,
            "pays": data.pays.option?.id || props.registry?.pays,
            "TypeModelOrg": data.TypeModelOrg.option?.id || props.registry?.TypeModelOrg,
            "StatutJuridique": data.StatutJuridique.option?.id || props.registry?.StatutJuridique,
            "Cctld": data.Cctld.option?.id || props.registry?.Cctld,
            // "CharteNommage": "<integer>",
            "Utilisateur": props.registry?.Utilisateur || states.utilisateur?.id ,
            "status": props.registry?.status || true
        }
        if (props.registry?.id){
            // console.log(o)
            dispatch(editRegistry(props.registry?.id,o))
        }else {
            dispatch(insertRegistry(o))
        }
        props.close(false)
    }
    return <div id={'RegistryForm'}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl label={() => t('nomRegistre')}>
                <>
                    <Controller
                        name="nomRegistre"
                        control={control}
                        rules={{required: true}}
                        defaultValue={props.registry?.nomRegistre}
                        render={({onChange, value}) => <Input value={value} onChange={onChange}/>}
                    />
                    {errors.nomRegistre && <span>{t('field_required')}</span>}
                </>
            </FormControl>


            <FormControl label={() => t('dateCreation')}>
                <>
                    <Controller
                        name="dateCreation"
                        control={control}
                        rules={{required: true}}
                        defaultValue={moment(props.registry?.dateCreation,'YYYY-MM-DD').toDate()}
                        render={({onChange, value}) => <DatePicker value={moment(value.date).toDate()} onChange={onChange}/>}
                    />
                    {errors.dateCreation && <span>{t('field_required')}</span>}
                </>
            </FormControl>

            <FormControl label={() => t('situationGeographique')}>
                <>
                    <Controller
                        name="situationGeographique"
                        control={control}
                        rules={{required: true}}
                        defaultValue={props.registry?.situationGeographique}
                        render={({onChange, value}) => <Input value={value} onChange={onChange}/>}
                    />
                    {errors.situationGeographique && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('pays')}>
                <>
                    <Controller
                        name="pays"
                        control={control}
                        rules={{required: true}}
                        defaultValue={{value:countries.filter(c=>c.alpha2Code===props.registry?.pays).map(c=> {
                                return  {label:c.translations[i18n.language]||c.name, id:c.alpha2Code,object:c}
                            })[0]}}
                        render={({onChange, value}) => <Select
                            options={countries.filter(c=>c.region.toLowerCase()==='africa').map(c=> {
                                return  {label:c.translations[i18n.language]||c.name, id:c.alpha2Code,object:c}
                            })}
                            value={value.value}
                            placeholder={!props.registry?.pays && "Select"}
                            onChange={onChange}
                        />}
                    />
                    {errors.pays && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('TypeModelOrg')}>
                <>
                    <Controller
                        name="TypeModelOrg"
                        control={control}
                        rules={{required: true}}
                        defaultValue={{value:states.typeModelOrg?.filter(t=>t.id === props.registry?.TypeModelOrg).map(m=>{return{label:m.intitule,id:m.id,object:m}})[0]}}
                        render={({onChange, value}) => <Select
                            options={states.typeModelOrg?.map(m=>{return{label:m.intitule,id:m.id,object:m}})||[]}
                            value={value.value}
                            placeholder={!props.registry?.TypeModelOrg && "Select"}
                            onChange={onChange}
                        />}
                    />
                    {errors.TypeModelOrg && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('StatutJuridique')}>
                <>
                    <Controller
                        name="StatutJuridique"
                        control={control}
                        rules={{required: true}}
                        defaultValue={{value:states.legalStatus?.filter(l=>l.id===props.registry?.StatutJuridique).map(m=>{return{label:m.libelle,id:m.id,object:m}})[0]}}
                        render={({onChange, value}) => <Select
                            options={states.legalStatus?.map(m=>{return{label:m.libelle,id:m.id,object:m}})||[]}
                            value={value.value}
                            placeholder={!props.registry?.StatutJuridique && "Select"}
                            onChange={onChange}
                        />}
                    />
                    {errors.StatutJuridique && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('Cctld')}>
                <>
                    <Controller
                        name="Cctld"
                        control={control}
                        rules={{required: true}}
                        defaultValue={{value:states.cctlds?.filter(c=>c.id===props.registry?.Cctld).map(m=>{return{label:m.intitule,id:m.id,object:m}})[0]}}
                        render={({onChange, value}) => <Select
                            options={states.cctlds?.map(m=>{return{label:m.intitule,id:m.id,object:m}})||[]}
                            value={value.value}
                            placeholder={!props.registry?.Cctld && "Select"}
                            onChange={onChange}
                        />}
                    />
                    {errors.Cctld && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <Button type={'submit'}>{t('enregistrer')}</Button>
        </form>
    </div>;
};

export default RegistryForm;
