import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import axios from "axios";

const TypeModelOrgWidget = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const [model, setModel] = React.useState()

    React.useLayoutEffect(() => {
        if (props.id){
            var config = {
                method: 'get',
                url: 'https://onda.africa/ondarest.php/records/TypeModelOrg/' + props.id,
                headers: {}
            };

            axios(config)
                .then(function (response) {
                    setModel(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }, [props.id])
    return <span id={'TypeModelOrgWidget'}>
        {model?.intitule}
    </span>;
};

export default TypeModelOrgWidget;
