import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import moment from "moment";
import {create} from "../../../redux/actions";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {Button} from "baseui/button";
import {Checkbox} from "baseui/checkbox";

const LitigeReglementForm = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const {register, handleSubmit, watch, errors, control} = useForm();
  const onSubmit = data => {
    let o = {
      "nomEntiteResponsable": data.nomEntiteResponsable,
      "dureeMoyenne": data.dureeMoyenne,
      "nombreDossier": data.nombreDossier,
      "compatibiliteUdrpIcann": data.compatibiliteUdrpIcann,
      "existenceProcedure": data.existenceProcedure,
      "date": moment().format('YYYY-MM-DD'),
      "Registre": props.registry
    }
    dispatch(create(o,'ReglementLitige','someLitigeReglements'))
    props.close(false)
  }
  return <div id={'LitigeReglementForm'}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl label={() => t('nomEntiteResponsable')}>
        <>
          <Controller
              name="nomEntiteResponsable"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input onChange={onChange}/>}
          />
          {errors.nomEntiteResponsable && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('nombreDossier')}>
        <>
          <Controller
              name="nombreDossier"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
          />
          {errors.nombreDossier && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('dureeMoyenne')}>
        <>
          <Controller
              name="dureeMoyenne"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
          />
          {errors.dureeMoyenne && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => ''}>
        <>
          <Controller
              name="compatibiliteUdrpIcann"
              control={control}
              // rules={{required: true}}
              defaultValue={false}
              render={({onChange, value}) => <Checkbox
                  checked={value}
                  onChange={e => {
                    onChange(e.target.checked)
                  }}
                  labelPlacement={"right"}
                  value={true}
              >
                {t('compatibiliteUdrpIcann')}
              </Checkbox>}
          />
          {errors.compatibiliteUdrpIcann && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => ''}>
        <>
          <Controller
              name="existenceProcedure"
              control={control}
              // rules={{required: true}}
              defaultValue={false}
              render={({onChange, value}) => <Checkbox
                  checked={value}
                  onChange={e => {
                    onChange(e.target.checked)
                  }}
                  labelPlacement={"right"}
                  value={true}
              >
                {t('existenceProcedure')}
              </Checkbox>}
          />
          {errors.existenceProcedure && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <Button type={'submit'}>{t('enregistrer')}</Button>
    </form>
  </div>;
};

export default LitigeReglementForm;
