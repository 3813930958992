import React from 'react';
import "./BusinessModelView.css"
import {useTranslation} from "react-i18next";
import CategoryWidget from "../../widgets/CategoryWidget";
const BusinessModelView = (props) => {
  const {t, i18n} = useTranslation();
  return <div className={'BusinessModelView'}>
    {/*<div>{t('intitule')}:{props.data.intitule}</div>*/}
    <div style={{fontSize:20,fontWeight:"bold"}}>{props.data.intitule}</div>
    <CategoryWidget id={props.data.Categorie}/>
    <div><span className={'text-muted'}>{t('monopoleVenteDirect')}:</span> {props.data.monopoleVenteDirect?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('politiqueTarifaire')}:</span> {props.data.politiqueTarifaire?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('processusVenteEnchere')}:</span> {props.data.processusVenteEnchere?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('processusVentePremium')}:</span> {props.data.processusVentePremium?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('processusVentePublicite')}:</span> {props.data.processusVentePublicite?t('yes'):t('no')}</div>
    <div><span className={'text-muted'}>{t('tarifEnregistrement')}:</span> {props.data.tarifEnregistrement}</div>
    <div><span className={'text-muted'}>{t('tarifModification')}:</span> {props.data.tarifModification}</div>
    <div><span className={'text-muted'}>{t('tarifRenouvellement')}:</span> {props.data.tarifRenouvellement}</div>
    <div><span className={'text-muted'}>{t('tarifSuppression')}:</span> {props.data.tarifSuppression}</div>
    <div><span className={'text-muted'}>{t('tarifTransfert')}:</span> {props.data.tarifTransfert}</div>
  </div>;
};

export default BusinessModelView;
