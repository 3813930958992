import React from 'react';
import {FormControl} from "baseui/form-control";
import {Controller, useForm} from "react-hook-form";
import {Input} from "baseui/input";
import {Select} from "baseui/select";
import {Checkbox} from "baseui/checkbox";
import {Button} from "baseui/button";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {create} from "../../../redux/actions";
import {DatePicker} from "baseui/datepicker";
import countries from "../../../scripts/countries";
import {Textarea} from "baseui/textarea";

const GouvCctldForm = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const {register, handleSubmit, watch, errors, control} = useForm();
  const onSubmit = data => {
    let o = {
      "cadreLegal": data.cadreLegal,
      "duree": data.duree,
      "projetRevision": data.projetRevision,
      "registreTechnique": data.registreTechnique.option.id,
      "dateCreationRegistreTechnique": moment(data.dateCreationRegistreTechnique.date).format('YYYY-MM-DD'),
      "commentaire": data.commentaire,
      "impactCovid": data.impactCovid,
      "situationGeographiqueRegistreTechnique": data.situationGeographiqueRegistreTechnique,
      "paysRegistreTechnique": data.paysRegistreTechnique.option.id,
      "date": moment().format('YYYY-MM-DD'),
      "Registre": Number(props.registry)
    }
    dispatch(create(o, 'GouvernanceCctld', 'someGouvCctlds'))
    // console.log(o)
    props.close(false)
  }
  return <div id={'GouvCctldForm'}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl label={() => t('duree')}>
        <>
          <Controller
              name="duree"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
          />
          {errors.duree && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => ''}>
        <>
          <Controller
              name="cadreLegal"
              control={control}
              // rules={{required: true}}
              defaultValue={false}
              render={({onChange, value}) => <Checkbox
                  checked={value}
                  onChange={e => {
                    onChange(e.target.checked)
                  }}
                  labelPlacement={"right"}
                  value={true}
              >
                {t('cadreLegal')}
              </Checkbox>}
          />
          {errors.cadreLegal && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => ''}>
        <>
          <Controller
              name="projetRevision"
              control={control}
              // rules={{required: true}}
              defaultValue={false}
              render={({onChange, value}) => <Checkbox
                  checked={value}
                  onChange={e => {
                    onChange(e.target.checked)
                  }}
                  labelPlacement={"right"}
                  value={true}
              >
                {t('projetRevision')}
              </Checkbox>}
          />
          {errors.projetRevision && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('dateCreationRegistreTechnique')}>
        <>
          <Controller
              name="dateCreationRegistreTechnique"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <DatePicker onChange={onChange}/>}
          />
          {errors.dateCreationRegistreTechnique && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('registreTechnique')}>
        <>
          <Controller
              name="registreTechnique"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Select
                  options={states.registries?.map(r=> {
                    return  {label:r.nomRegistre+' ('+r.pays+')', id:r.id}
                  })}
                  value={value.value}
                  placeholder="Select"
                  onChange={onChange}
              />}
          />
          {errors.registreTechnique && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('paysRegistreTechnique')}>
        <>
          <Controller
              name="paysRegistreTechnique"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Select
                  options={countries.filter(c=>c.region.toLowerCase()==='africa').map(c=> {
                    return  {label:c.translations[i18n.language]||c.name, id:c.alpha2Code,object:c}
                  })}
                  value={value.value}
                  placeholder="Select"
                  onChange={onChange}
              />}
          />
          {errors.paysRegistreTechnique && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('commentaire')}>
        <>
          <Controller
              name="commentaire"
              control={control}
              defaultValue={''}
              render={({onChange, value}) => <Textarea
                  value={value}
                  onChange={onChange}
                  clearOnEscape
              />}
          />
          {errors.commentaire && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('impactCovid')}>
        <>
          <Controller
              name="impactCovid"
              control={control}
              defaultValue={''}
              render={({onChange, value}) => <Textarea
                  value={value}
                  onChange={onChange}
                  clearOnEscape
              />}
          />
          {errors.impactCovid && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => t('situationGeographiqueRegistreTechnique')}>
        <>
          <Controller
              name="situationGeographiqueRegistreTechnique"
              control={control}
              defaultValue={''}
              render={({onChange, value}) => <Textarea
                  value={value}
                  onChange={onChange}
                  clearOnEscape
              />}
          />
          {errors.situationGeographiqueRegistreTechnique && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <Button type={'submit'}>{t('enregistrer')}</Button>
    </form>
  </div>;
};

export default GouvCctldForm;
