import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {FlexGrid, FlexGridItem} from "baseui/flex-grid";
import RegistrarViewWidget from "../../widgets/RegistrarViewWidget";
import SectionTitle from "../../widgets/SectionTitle";
import {Button, SIZE} from "baseui/button";
import {Modal, ModalBody, ModalHeader, ROLE} from "baseui/modal";
import RegistrarForm from "../../forms/RegistrarForm";
import {Spinner} from "baseui/spinner";

const RegistrarList = () => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const [isOpen, setIsOpen] = React.useState(false);
  const [list, setList] = React.useState(states.registrars||[]);
  const history = useHistory()
  React.useEffect(() => {
    setList(states.registrars)
    dispatch({type: "ONE_REGISTRY", key: 'registrar', payload: undefined});
  }, [states.registrars])

  return <div id={'RegistrarList'}>
    <div style={{display:"flex",justifyContent:"space-between",paddingTop:15, alignItems:"center"}}>
      <SectionTitle title={t('registrar_list')}/>
      <div>
        {states.utilisateur?.Role <=3 && <Button
            onClick={() => setIsOpen(true)}>
          {t('add_Registrar')}
        </Button>}
      </div>
    </div>
    <div style={{marginTop:15}}>
      {!list && <Spinner color={'#fe9957'}/>}
      {list?.length===0 && <div>{t('no_data_yet')}</div>}
      <FlexGrid
          flexGridColumnCount={[1, 1, 2, 3]}
          flexGridColumnGap="scale800"
          flexGridRowGap="scale800"
      >
        {list?.map(d => <FlexGridItem>
          <RegistrarViewWidget data={d}/>
        </FlexGridItem>)}
      </FlexGrid>
    </div>

    <Modal
        onClose={() => setIsOpen(false)}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
    >
      <ModalHeader></ModalHeader>
      <ModalBody>
        <RegistrarForm close={setIsOpen}/>
      </ModalBody>
      {/*<ModalFooter></ModalFooter>*/}
    </Modal>
  </div>;
};

export default RegistrarList;
