import React from 'react';
import "./RegistryIndicatorsView.css"
import {Tab, Tabs} from "baseui/tabs";
import {useTranslation} from "react-i18next";
import BusinessModelList from "../../lists/BusinessModelList";
import BudgetExerciceList from "../../lists/BudgetExerciceList";
import AutomatProcessList from "../../lists/AutomatProcessList";
import ServicePlusList from "../../lists/ServicePlusList";
import GouvCctldList from "../../lists/GouvCctldList";
import LitigeReglementList from "../../lists/LitigeReglementList";
import AccreditationProcessList from "../../lists/AccreditationProcessList";
import EreputationList from "../../lists/EreputationList";
import NameChartList from "../../lists/NameChartList";
import DirectionList from "../../lists/DirectionList";
import {useDispatch} from "react-redux";
import {Spinner} from "baseui/spinner";
import StatNomDomaineCctldList from "../../lists/StatNomDomaineCctldList";

const RegistryIndicatorsView = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [activeKey, setActiveKey] = React.useState("0");
    React.useLayoutEffect(()=>{
        dispatch({type: "SET", key: 'someStatNomDomaineCctld', payload: undefined});
        dispatch({type: "SET", key: 'someDirections', payload: undefined});
        dispatch({type: "SET", key: 'someBusinessModels', payload: undefined});
        dispatch({type: "SET", key: 'someBudgetExercices', payload: undefined});
        dispatch({type: "SET", key: 'someAutomatProcesss', payload: undefined});
        dispatch({type: "SET", key: 'someServicePlus', payload: undefined});
        dispatch({type: "SET", key: 'someGouvCctlds', payload: undefined});
        dispatch({type: "SET", key: 'someLitigeReglements', payload: undefined});
        dispatch({type: "SET", key: 'someAccreditationProcess', payload: undefined});
        dispatch({type: "SET", key: 'someEreputations', payload: undefined});
        dispatch({type: "SET", key: 'someNameCharts', payload: undefined});

    },[])
    return <div id={'RegistryIndicatorsView'}>
        <Tabs
            activeKey={activeKey}
            onChange={({activeKey}) => setActiveKey(activeKey)}
            fill={'fixed'}
            activateOnFocus
            // orientation={'vertical'}
        >
            <Tab title={t('direction')}>
                <DirectionList registry={props.registry}/>
            </Tab>
            <Tab title={t('stat_domaine')}>
                <StatNomDomaineCctldList registry={props.registry}/>
            </Tab>
            <Tab title={t('business_model')}>
                <BusinessModelList registry={props.registry}/>
            </Tab>
            <Tab title={t('exercice_budgetaire')}>
                <BudgetExerciceList registry={props.registry}/>
            </Tab>
            <Tab title={t('process_automatisation')}>
                <AutomatProcessList registry={props.registry}/>
            </Tab>
            <Tab title={t('service_additionnel')}>
                <ServicePlusList registry={props.registry}/>
            </Tab>
            <Tab title={t('gouv_cctld')}>
                <GouvCctldList registry={props.registry}/>
            </Tab>
            <Tab title={t('reglement_litige')}>
                <LitigeReglementList registry={props.registry}/>
            </Tab>
            <Tab title={t('procedure_accreditation')}>
                <AccreditationProcessList registry={props.registry}/>
            </Tab>
            <Tab title={t('ereputation')}>
                <EreputationList registry={props.registry}/>
            </Tab>
            <Tab title={t('charte_nommage')}>
                <NameChartList registry={props.registry}/>
            </Tab>
        </Tabs>
    </div>;
};

export default RegistryIndicatorsView;
