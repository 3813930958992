import React from 'react';
import "./DirectionView.css"
import {useTranslation} from "react-i18next";

const DirectionView = (props) => {
    const {t, i18n} = useTranslation();
    return <div className={'DirectionView'}>
        <div style={{fontSize: 20, fontWeight: "bold"}}>{props.data.labelle}</div>
        <div><span className={'text-muted'}>{t('nombreEmploye')}:</span>{props.data.nombreEmploye}</div>
    </div>;
};

export default DirectionView;
