import React from 'react';
import {Link} from "react-router-dom";
import SignUpForm from "../forms/SignUpForm";
import SignInForm from "../forms/SignInForm";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import logo from "../../assets/logo.png"
import {Spinner} from "baseui/spinner";
const Auth = () => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const session = localStorage.getItem('ondaUser')
    React.useLayoutEffect(() => {
        if (session) {
            window.location = '/dash'
        }
    }, [session])
    const [form, setForm] = React.useState('signIn')

    return <div id={'Auth'}>
        <div style={{width: 300, margin: "auto", marginTop: '20vh'}}>
            <div className={'text-center'}>
                <img src={logo} alt="onda" style={{maxWidth:256}}/>
            </div>
            {form === 'signUp' && <SignUpForm setForm={setForm}/>}
            {form === 'signIn' && <SignInForm setForm={setForm}/>}
            <hr/>
            <p>
                {form === 'signIn' && <Link to={'#'} onClick={() => setForm('signUp')}>{t('register')}</Link>}
                {form === 'signUp' && <Link to={'#'} onClick={() => setForm('signIn')}>{t('login')}</Link>}
            </p>
            <div>
                {/*{states.authLoading===true && <Spinner color={'#ff9957'}/>}*/}
            </div>
        </div>
    </div>;
};

export default Auth;
