import React from 'react';
import "./ServicePlusView.css"
import {useTranslation} from "react-i18next";
const ServicePlusView = (props) => {
  const {t, i18n} = useTranslation();
  return <div className={'ServicePlusView'}>
    <span>{t('libelle')}:</span>
    <div style={{fontSize: 20, fontWeight: "bold"}}>{props.data.libelle}</div>
  </div>;
};

export default ServicePlusView;
