import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {FlexGrid, FlexGridItem} from "baseui/flex-grid";
import DirectionView from "../../views/DirectionView";
import {Button} from "baseui/button";
import {Modal, ModalBody, ModalHeader} from "baseui/modal";
import ServicePlusForm from "../../forms/ServicePlusForm";
import {getSomeServicePlus} from "../../../redux/actions";
import ServicePlusView from "../../views/ServicePlusView";
import {Spinner} from "baseui/spinner";

const ServicePlusList = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const [isOpen, setIsOpen] = React.useState(false);
  const history = useHistory()
  React.useLayoutEffect(() => {
    dispatch(getSomeServicePlus(props.registry))
  }, [])
  const [list, setList] = React.useState(undefined);
  React.useLayoutEffect(() => {
    setList(states.someServicePlus)
  }, [states.someServicePlus])
  const itemProps = {
    backgroundColor: 'mono300',
    height: 'scale1000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return <div id={'ServicePlusList'}>
    {!list && <Spinner color={'#fe9957'}/>}
    {list?.length===0 && <div>{t('no_data_yet')}</div>}
    <FlexGrid
        flexGridColumnCount={[1, 1, 2, 3]}
        flexGridColumnGap="scale800"
        flexGridRowGap="scale800"
    >
      {list?.map(d => <FlexGridItem>
        <ServicePlusView data={d}/>
      </FlexGridItem>)}
    </FlexGrid>
    <p>
      {states.utilisateur?.id === states.registry?.Utilisateur && <Button onClick={e=>setIsOpen(true)}>{t('add_ServicePlus')}</Button>}
    </p>

    <Modal
        onClose={() => setIsOpen(false)}
        closeable
        isOpen={isOpen}
        animate
        autoFocus
    >
      <ModalHeader/>
      <ModalBody>
        <ServicePlusForm registry={props.registry} close={setIsOpen}/>
      </ModalBody>
    </Modal>
  </div>;
};

export default ServicePlusList;
