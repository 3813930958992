import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import moment from "moment";
import {insertRegistrar} from "../../../redux/actions";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {DatePicker} from "baseui/datepicker";
import {Select} from "baseui/select";
import countries from "../../../scripts/countries";
import {Button} from "baseui/button";

const RegistrarForm = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const {register, handleSubmit, watch, errors, control} = useForm();
    const onSubmit = data => {
        let o = {
            // "id":null,
            "sigle": data.sigle,
            "denomination": data.denomination,
            "telephone": data.telephone,
            "email": data.email,
            "accrediteIcann": "<boolean>",
            "situationGeographique": data.situationGeographique,
            "pays": data.pays.option.id,
            "Utilisateur": states.utilisateur?.id,
            "status": "CREATED"
        }
        dispatch(insertRegistrar(o))
        props.close(false)
    }
    return <div id={'RegistrarForm'}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl label={() => t('sigle')}>
                <>
                    <Controller
                        name="sigle"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input onChange={onChange}/>}
                    />
                    {errors.sigle && <span>{t('field_required')}</span>}
                </>
            </FormControl>

            <FormControl label={() => t('denomination')}>
                <>
                    <Controller
                        name="denomination"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input onChange={onChange}/>}
                    />
                    {errors.denomination && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('telephone')}>
                <>
                    <Controller
                        name="telephone"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input onChange={onChange}/>}
                    />
                    {errors.telephone && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('email')}>
                <>
                    <Controller
                        name="email"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input onChange={onChange}/>}
                    />
                    {errors.email && <span>{t('field_required')}</span>}
                </>
            </FormControl>


            <FormControl label={() => t('situationGeographique')}>
                <>
                    <Controller
                        name="situationGeographique"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input onChange={onChange}/>}
                    />
                    {errors.situationGeographique && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('pays')}>
                <>
                    <Controller
                        name="pays"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Select
                            options={countries.filter(c => c.region.toLowerCase() === 'africa').map(c => {
                                return {label: c.translations[i18n.language] || c.name, id: c.alpha2Code, object: c}
                            })}
                            value={value.value}
                            placeholder="Select"
                            onChange={onChange}
                        />}
                    />
                    {errors.pays && <span>{t('field_required')}</span>}
                </>
            </FormControl>

            <Button type={'submit'}>{t('enregistrer')}</Button>
        </form>
    </div>;
};

export default RegistrarForm;
