import axios from "axios";
import {DeviceUUID} from "device-uuid"
import store from "./store";

const apiUrl = 'https://onda.africa/ondarest.php'
//axios interceptors
axios.defaults.withCredentials = true
axios.interceptors.request.use(function (config) {
    config.withCredentials = true
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log(error);
    if (error.response?.status === 401) getOut()
    return Promise.reject(error);
});

//actions
export function doSomething(payload) {
    return {type: "SET", payload}
}

export function getRemoteData() {
    return function (dispatch) {
        return axios.get('https://jsonplaceholder.typicode.com/posts')
            .then(json => {
                dispatch({type: "SET", key: 'remoteData', payload: json.data});
                //window.location = 'https://google.com';
            });
    };
}

// export function func() {
//     return function (dispatch) {
//         return null
//     }
// }
const getOut = () => {
    let config = {
        method: 'post',
        url: apiUrl + '/logout',
        headers: {
            'Content-Type': 'application/json',
        }
    };
    // axios(config).then(function (response) {
    //     console.log(response)
    //     window.location = '/'
    // })
    localStorage.removeItem('ondaUser')
    localStorage.removeItem('ondaEmail')
    localStorage.removeItem('ondaToken')
    window.location = '/'
}

export function checkUtilisateur(id) {
    return function (dispatch) {
        var config = {
            method: 'get',
            url: apiUrl + '/records/Utilisateur/?filter=user,eq,' + id,
        };

        axios(config)
            .then(function (response) {
                // console.log(JSON.stringify(response.data.records));
                if (response.data.records.length) {
                    dispatch({type: "UTILISATEUR", key: 'utilisateur', payload: response.data.records[0]})
                    dispatch({type: "ROLE", key: 'admin', payload: response.data.records[0].Role === 1})
                } else dispatch({type: "SET_INFOS", key: 'setInfos', payload: true})
            })
            .catch(function (error) {
                console.log(error);
            });

    }
}

export function setMe() {
    return function (dispatch) {
        var axios = require('axios');

        var config = {
            method: 'get',
            url: apiUrl + '/me',
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "ME", key: 'me', payload: response.data});
                dispatch(checkUtilisateur(response.data.id))
            })
            .catch(function (error) {
                console.log(error);
            });

    }
}

export function getUsers() {
    return function (dispatch) {
        var config = {
            method: 'get',
            url: apiUrl + '/records/Utilisateur',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'users', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllRegistry() {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Registre',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'registries', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllRegistryCount() {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Registre',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'registriesCount', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllMyRegistry(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Registre?filter=Utilisateur,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'registries', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllRegistrar() {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Registrar',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'registrars', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllRegistrarCount() {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Registrar',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'registrarsCount', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllMyRegistrar(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Registrar?filter=Utilisateur,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'registrars', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getOneRegistry(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Registre/' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'registry', payload: response.data});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllRole() {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Role',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'roles', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllTypeModelOrg() {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/TypeModelOrg',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'typeModelOrg', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllStatutJuridique() {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/StatutJuridique',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'legalStatus', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllCctld() {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Cctld',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'cctlds', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllCategories() {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Categorie',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'categories', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllTypeGestions() {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/TypeGestion',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'typeGestions', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getAllSolutionTechniques() {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/SolutionTechnique',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'solutionTechniques', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function insertRegistry(o) {
    return function (dispatch) {
        var data = JSON.stringify(o);

        var config = {
            method: 'post',
            url: apiUrl + '/records/Registre',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                dispatch(getAllMyRegistry(o.Utilisateur))
                dispatch({type: "SET", key: 'registry', payload: {...o}});
                // console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });

    }
}

export function editRegistry(id, o) {
    return function (dispatch) {
        var data = JSON.stringify(o);

        var config = {
            method: 'post',
            url: apiUrl + '/records/Registre/' + id,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                dispatch(getAllMyRegistry(o.Utilisateur))
                dispatch({type: "SET", key: 'registry', payload: {...o,id:id}});
                // console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });

    }
}

export function insertRegistrar(o) {
    return function (dispatch) {
        var data = JSON.stringify(o);

        var config = {
            method: 'post',
            url: apiUrl + '/records/Registrar',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                dispatch(getAllMyRegistrar(o.Utilisateur))
                // console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });

    }
}

export function getSomeDirections(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Direction?filter=Registre,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'someDirections', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}
export function getSomeDSNStats(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/StatNomDomaineCctld?filter=Registre,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'someStatNomDomaineCctld', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getSomeBusinessModels(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/BusinessModel?filter=Registre,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'someBusinessModels', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getSomeBudgetExercices(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/ExerciceBudgetaire?filter=Registre,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'someBudgetExercices', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getSomeAutomatProcesss(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/ProcessusAutomatisation?filter=Registre,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'someAutomatProcesss', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getSomeServicePlus(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/ServiceAdditionnel?filter=Registre,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'someServicePlus', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getSomeGouvCctlds(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/GouvernanceCctld?filter=Registre,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'someGouvCctlds', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getSomeLitigeReglements(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/ReglementLitige?filter=Registre,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'someLitigeReglements', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getSomeAccreditationProcess(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/ProcedureAccreditation?filter=Registre,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'someAccreditationProcess', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getSomeEreputations(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/Ereputation?filter=Registre,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'someEreputations', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function getSomeNameCharts(id) {
    return function (dispatch) {

        var config = {
            method: 'get',
            url: apiUrl + '/records/CharteNommage?filter=Registre,eq,' + id,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: 'someNameCharts', payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function setInfo(object, table) {
    return function (dispatch) {
        var data = JSON.stringify(object);

        var config = {
            method: 'post',
            url: apiUrl + '/records/' + table,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                dispatch(setMe())
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function create(object, table, storeKey) {
    return function (dispatch) {
        var data = JSON.stringify(object);

        var config = {
            method: 'post',
            url: apiUrl + '/records/' + table,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                dispatch(select(table, storeKey, '?filter=Registre,eq,' + object.Registre))
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function update(object, table, storeKey) {
    return function (dispatch) {
        let oid = object.id
        delete object.id

        var data = JSON.stringify(object);

        var config = {
            method: 'post',
            url: apiUrl + '/records/' + table + '/' + oid,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                // console.log(response);
                dispatch(select(table, storeKey, ''))
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

export function select(table, storeKey, filter) {
    return function (dispatch) {
        var config = {
            method: 'get',
            url: apiUrl + '/records/' + table + filter,
            headers: {}
        };

        axios(config)
            .then(function (response) {
                dispatch({type: "SET", key: storeKey, payload: response.data.records});
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

// export function func() {
//     return function (dispatch) {
//         return null
//     }
// }


