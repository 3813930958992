import React from 'react';
import {FormControl} from "baseui/form-control";
import {Controller, useForm} from "react-hook-form";
import {Input} from "baseui/input";
import {Button} from "baseui/button";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {create} from "../../../redux/actions";
import {Checkbox} from "baseui/checkbox";
import {Select} from "baseui/select";

const AutomatProcessForm = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const {register, handleSubmit, watch, errors, control} = useForm();
  const onSubmit = data => {
    let o = {
      // "id": "<integer>",
      "dureeTotale": data.dureeTotale,
      "existenceOutilRecherche": data.existenceOutilRecherche,
      "existenceServiceWhois": data.existenceServiceWhois,
      "date": moment().format('YYYY-MM-DD'),
      "Registre": props.registry
    }
    dispatch(create(o,'ProcessusAutomatisation','someAutomatProcesss'))
    props.close(false)
  }
  return <div id={'AutomatProcessForm'}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl label={() => t('dureeTotale')}>
        <>
          <Controller
              name="dureeTotale"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input onChange={onChange}/>}
          />
          {errors.dureeTotale && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => ''}>
        <>
          <Controller
              name="existenceOutilRecherche"
              control={control}
              // rules={{required: true}}
              defaultValue={false}
              render={({onChange, value}) => <Checkbox
                  checked={value}
                  onChange={e => {
                    onChange(e.target.checked)
                  }}
                  labelPlacement={"right"}
                  value={true}
              >
                {t('existenceOutilRecherche')}
              </Checkbox>}
          />
          {errors.existenceOutilRecherche && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => ''}>
        <>
          <Controller
              name="existenceServiceWhois"
              control={control}
              // rules={{required: true}}
              defaultValue={false}
              render={({onChange, value}) => <Checkbox
                  checked={value}
                  onChange={e => {
                    onChange(e.target.checked)
                  }}
                  labelPlacement={"right"}
                  value={true}
              >
                {t('existenceServiceWhois')}
              </Checkbox>}
          />
          {errors.existenceServiceWhois && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <Button type={'submit'}>{t('enregistrer')}</Button>
    </form>
  </div>;
};

export default AutomatProcessForm;
