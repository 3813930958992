import React from 'react';
import {FormControl} from "baseui/form-control";
import {Controller, useForm} from "react-hook-form";
import {Input} from "baseui/input";
import {Select} from "baseui/select";
import {Checkbox} from "baseui/checkbox";
import {Button} from "baseui/button";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {create} from "../../../redux/actions";

const EreputationForm = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const {register, handleSubmit, watch, errors, control} = useForm();
    const onSubmit = data => {
        let o = {
            "politiqueCommunication": data.politiqueCommunication,
            "politiqueMarketing": data.politiqueMarketing,
            "pageFacebook": data.pageFacebook,
            "pageLinkedIn": data.pageLinkedIn,
            "pageInstagram": data.pageInstagram,
            "pageTwitter": data.pageTwitter,
            "nombreCampagneCommunication": data.nombreCampagneCommunication,
            "date": moment().format('YYYY-MM-DD'),
            "Registre": Number(props.registry)
        }
        dispatch(create(o, 'Ereputation', 'someEreputations'))
        // console.log(o)
        props.close(false)
    }
    return <div id={'EreputationForm'}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl label={() => t('nombreCampagneCommunication')}>
                <>
                    <Controller
                        name="nombreCampagneCommunication"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
                    />
                    {errors.nombreCampagneCommunication && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => ''}>
                <>
                    <Controller
                        name="politiqueCommunication"
                        control={control}
                        // rules={{required: true}}
                        defaultValue={false}
                        render={({onChange, value}) => <Checkbox
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked)
                            }}
                            labelPlacement={"right"}
                            value={true}
                        >
                            {t('politiqueCommunication')}
                        </Checkbox>}
                    />
                    {errors.politiqueCommunication && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => ''}>
                <>
                    <Controller
                        name="politiqueMarketing"
                        control={control}
                        // rules={{required: true}}
                        defaultValue={false}
                        render={({onChange, value}) => <Checkbox
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked)
                            }}
                            labelPlacement={"right"}
                            value={true}
                        >
                            {t('politiqueMarketing')}
                        </Checkbox>}
                    />
                    {errors.politiqueMarketing && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => ''}>
                <>
                    <Controller
                        name="pageFacebook"
                        control={control}
                        // rules={{required: true}}
                        defaultValue={false}
                        render={({onChange, value}) => <Checkbox
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked)
                            }}
                            labelPlacement={"right"}
                            value={true}
                        >
                            {t('pageFacebook')}
                        </Checkbox>}
                    />
                    {errors.pageFacebook && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => ''}>
                <>
                    <Controller
                        name="pageLinkedIn"
                        control={control}
                        // rules={{required: true}}
                        defaultValue={false}
                        render={({onChange, value}) => <Checkbox
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked)
                            }}
                            labelPlacement={"right"}
                            value={true}
                        >
                            {t('pageLinkedIn')}
                        </Checkbox>}
                    />
                    {errors.pageLinkedIn && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => ''}>
                <>
                    <Controller
                        name="pageInstagram"
                        control={control}
                        // rules={{required: true}}
                        defaultValue={false}
                        render={({onChange, value}) => <Checkbox
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked)
                            }}
                            labelPlacement={"right"}
                            value={true}
                        >
                            {t('pageInstagram')}
                        </Checkbox>}
                    />
                    {errors.pageInstagram && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => ''}>
                <>
                    <Controller
                        name="pageTwitter"
                        control={control}
                        // rules={{required: true}}
                        defaultValue={false}
                        render={({onChange, value}) => <Checkbox
                            checked={value}
                            onChange={e => {
                                onChange(e.target.checked)
                                console.log(value)
                            }}
                            labelPlacement={"right"}
                            value={true}
                        >
                            {t('pageTwitter')}
                        </Checkbox>}
                    />
                    {errors.pageTwitter && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <Button type={'submit'}>{t('enregistrer')}</Button>
        </form>
    </div>;
};

export default EreputationForm;
