import React from 'react';
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import moment from "moment";
import {create} from "../../../redux/actions";
import {FormControl} from "baseui/form-control";
import {Input} from "baseui/input";
import {Checkbox} from "baseui/checkbox";
import {Button} from "baseui/button";

const NameChartForm = (props) => {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const states = useSelector(state => state, shallowEqual);
  const {register, handleSubmit, watch, errors, control} = useForm();
  const onSubmit = data => {
    let o = {
      "nature": data.nature,
      "accessibiliteNationaux": data.accessibiliteNationaux,
      "accessibiliteEtranger": data.accessibiliteEtranger,
      "accessibilitePersonnePhysique": data.accessibilitePersonnePhysique,
      "accessibilitePersonneMorale": data.accessibilitePersonneMorale,
      "date": moment().format('YYYY-MM-DD'),
      "Registre": Number(props.registry)
    }
    dispatch(create(o, 'CharteNommage', 'someNameCharts'))
    // console.log(o)
    props.close(false)
  }
  return <div id={'NameChartForm'}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl label={() => t('nature')}>
        <>
          <Controller
              name="nature"
              control={control}
              rules={{required: true}}
              defaultValue={''}
              render={({onChange, value}) => <Input onChange={onChange}/>}
          />
          {errors.nature && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => ''}>
        <>
          <Controller
              name="accessibiliteNationaux"
              control={control}
              // rules={{required: true}}
              defaultValue={false}
              render={({onChange, value}) => <Checkbox
                  checked={value}
                  onChange={e => {
                    onChange(e.target.checked)
                  }}
                  labelPlacement={"right"}
                  value={true}
              >
                {t('accessibiliteNationaux')}
              </Checkbox>}
          />
          {errors.accessibiliteNationaux && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => ''}>
        <>
          <Controller
              name="accessibiliteEtranger"
              control={control}
              // rules={{required: true}}
              defaultValue={false}
              render={({onChange, value}) => <Checkbox
                  checked={value}
                  onChange={e => {
                    onChange(e.target.checked)
                  }}
                  labelPlacement={"right"}
                  value={true}
              >
                {t('accessibiliteEtranger')}
              </Checkbox>}
          />
          {errors.accessibiliteEtranger && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => ''}>
        <>
          <Controller
              name="accessibilitePersonnePhysique"
              control={control}
              // rules={{required: true}}
              defaultValue={false}
              render={({onChange, value}) => <Checkbox
                  checked={value}
                  onChange={e => {
                    onChange(e.target.checked)
                  }}
                  labelPlacement={"right"}
                  value={true}
              >
                {t('accessibilitePersonnePhysique')}
              </Checkbox>}
          />
          {errors.accessibilitePersonnePhysique && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <FormControl label={() => ''}>
        <>
          <Controller
              name="accessibilitePersonneMorale"
              control={control}
              // rules={{required: true}}
              defaultValue={false}
              render={({onChange, value}) => <Checkbox
                  checked={value}
                  onChange={e => {
                    onChange(e.target.checked)
                  }}
                  labelPlacement={"right"}
                  value={true}
              >
                {t('accessibilitePersonneMorale')}
              </Checkbox>}
          />
          {errors.accessibilitePersonneMorale && <span>{t('field_required')}</span>}
        </>
      </FormControl>
      <Button type={'submit'}>{t('enregistrer')}</Button>
    </form>
  </div>;
};

export default NameChartForm;
