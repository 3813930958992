import React from 'react';
import "./AccreditationProcessView.css"
import {useTranslation} from "react-i18next";

const AccreditationProcessView = (props) => {
    const {t, i18n} = useTranslation();
    return <div className={'AccreditationProcessView'}>
        <div><span className={'text-muted'}>{t('nature')}:</span> {props.data.nature}</div>
        <div><span className={'text-muted'}>{t('nombreAccrediteIcann')}:</span> {props.data.nombreAccrediteIcann}</div>
        <div><span className={'text-muted'}>{t('nombreRegistrarAgree')}:</span> {props.data.nombreRegistrarAgree}</div>
        <div><span className={'text-muted'}>{t('nombreRegistrarInternationaux')}:</span> {props.data.nombreRegistrarInternationaux}</div>
        <div><span className={'text-muted'}>{t('nombreRegistrarNationaux')}:</span> {props.data.nombreRegistrarNationaux}</div>
    </div>;
};

export default AccreditationProcessView;
