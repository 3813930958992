import React from 'react';
import {FormControl} from "baseui/form-control";
import {Controller, useForm} from "react-hook-form";
import {Input} from "baseui/input";
import {useTranslation} from "react-i18next";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {insertRegistry} from "../../../redux/actions";
import {Button} from "baseui/button";
import {create} from "../../../redux/actions"

const DirectionForm = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const states = useSelector(state => state, shallowEqual);
    const {register, handleSubmit, watch, errors, control} = useForm();
    const onSubmit = data => {
        let o = {
            "labelle": data.labelle,
            "nombreEmploye": data.nombreEmploye,
            "date": moment().format('YYYY-MM-DD'),
            "Registre": props.registry
        }
        dispatch(create(o,'Direction','someDirections'))
        props.close(false)
    }
    return <div id={'DirectionForm'}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl label={() => t('labelle')}>
                <>
                    <Controller
                        name="labelle"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input onChange={onChange}/>}
                    />
                    {errors.labelle && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <FormControl label={() => t('nombreEmploye')}>
                <>
                    <Controller
                        name="nombreEmploye"
                        control={control}
                        rules={{required: true}}
                        defaultValue={''}
                        render={({onChange, value}) => <Input type={'number'} onChange={onChange}/>}
                    />
                    {errors.nombreEmploye && <span>{t('field_required')}</span>}
                </>
            </FormControl>
            <Button type={'submit'}>{t('enregistrer')}</Button>
        </form>
    </div>;
};

export default DirectionForm;
