import React from 'react';
import "./Dash.css"
import DashHeader from "../DashHeader";
import {Grid, Cell} from 'baseui/layout-grid';
import DashLeft from "../DashLeft";
import {Tab} from "baseui/tabs";
import {Route, useRouteMatch} from "react-router-dom";
import UserList from "../lists/UserList";
import RegistryList from "../lists/RegistryList";
import RegistrarList from "../lists/RegistrarList";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    getAllCategories,
    getAllCctld,
    getAllMyRegistrar,
    getAllMyRegistry,
    getAllRegistrar, getAllRegistrarCount,
    getAllRegistry, getAllRegistryCount,
    getAllRole, getAllSolutionTechniques,
    getAllStatutJuridique, getAllTypeGestions,
    getAllTypeModelOrg, setMe
} from "../../redux/actions";
import RegistryView from "../views/RegistryView";
import {useTranslation} from "react-i18next";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "baseui/modal";
import UtilisateurForm from "../forms/UtilisateurForm";
import DashStats from "../DashStats";

const Dash = () => {
    const {t, i18n} = useTranslation();
    const states = useSelector(state => state, shallowEqual);
    let {path, url} = useRouteMatch();
    const dispatch = useDispatch();
    const session = localStorage.getItem('ondaUser')
    const [isOpen, setIsOpen] = React.useState(false);

    React.useLayoutEffect(() => {
        if (!session) {
            window.location = '/auth'
        } else {
            dispatch(setMe())
        }
    }, [session])

    React.useEffect(() => {
        // dispatch(getAllRole())
        // dispatch(getAllTypeModelOrg())
        // dispatch(getAllStatutJuridique())
        // dispatch(getAllCctld())
        // dispatch(getAllCategories())
        // dispatch(getAllTypeGestions())
        // dispatch(getAllSolutionTechniques())
        // dispatch(getAllRegistry())
    }, [])
    React.useEffect(() => {
        dispatch(getAllRole())
        dispatch(getAllTypeModelOrg())
        dispatch(getAllStatutJuridique())
        dispatch(getAllCctld())
        dispatch(getAllCategories())
        dispatch(getAllTypeGestions())
        dispatch(getAllSolutionTechniques())

    }, [states.utilisateur])
    React.useEffect(() => {
        if (states.utilisateur) {
            // if (states.admin) {
            //     dispatch(getAllRegistry())
            //     dispatch(getAllRegistrar())
            // }
            // if (!states.admin) {
                dispatch(getAllMyRegistry(states.utilisateur?.id))
                dispatch(getAllMyRegistrar(states.utilisateur?.id))
            // }
                dispatch(getAllRegistryCount())
                dispatch(getAllRegistrarCount())
        }

    }, [states.admin])

    React.useEffect(() => {
        if (states.setInfos === true) {
            setIsOpen(true)
        }
    }, [states.setInfos])
    return <div id={'Dash'}>
        <DashHeader/>
        <Grid>
            <Cell span={[0, 2, 3]}>
                <DashLeft/>
            </Cell>
            <Cell span={[12, 6, 9]}>
                <main style={{height: 'calc( 100vh - 80px )', overflowY: 'auto'}}>
                    <Route exact path={path}>
                        <DashStats/>
                    </Route>
                    <Route exact path={`${path}/users`}>
                        {states.utilisateur?.Role === 1 ? <UserList/> : <div>no access</div>}
                    </Route>
                    <Route exact path={`${path}/registries`}>
                        <RegistryList/>
                    </Route>
                    <Route exact path={`${path}/registry/:id`}>
                        <RegistryView/>
                    </Route>
                    <Route exact path={`${path}/registrars`}>
                        <RegistrarList/>
                    </Route>
                    <Route exact path={`${path}/publications`}>
                        publications
                    </Route>
                </main>
            </Cell>
        </Grid>

        <Modal
            onClose={() => setIsOpen(states.setInfos)}
            isOpen={isOpen}
            animate
            autoFocus
            role={'dialog'}
        >
            <ModalHeader>{t('must_complete_profile')}</ModalHeader>
            <ModalBody>
                <UtilisateurForm close={setIsOpen}/>
            </ModalBody>
        </Modal>
    </div>;
};

export default Dash;
